import React, { useState, useContext } from "react";
import "./AddCustomWheel.css";

import CustomButton from "../CustomButton/CustomButton";
import Overlay from "../Overlay/Overlay";

import WheelApiService from "../../Services/wheel-api-service";
import WheelContext from "../../Context/WheelContext";
import TokenService from "../../Services/token-service";
import { useHistory } from "react-router";
import Utils from "../../Utils/Utils";

import { IoMdAddCircle } from "react-icons/io";
import { AiFillMinusCircle } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";

import ReactLoading from "react-loading";

const AddCustomWheel = () => {
  const W_CONTEXT = useContext(WheelContext);

  const history = useHistory();

  const [brand, setBrand] = useState("");
  const [boltPattern, setBoltPattern] = useState("");
  const [showBoltPatternOverlay, setShowBoltPatterOverlay] = useState(false);
  const [showSecondBoltPattern, setShowSecondBoltPattern] = useState(false);
  const [secondBoltPattern, setSecondBoltPattern] = useState("");
  const [showSecondBoltPatternOverlay, setShowSecondBoltPatternOverlay] =
    useState(false);
  const [diameter, setDiameter] = useState("");
  const [width, setWidth] = useState("");
  const [secondDiameter, setSecondDiameter] = useState("");
  const [secondWidth, setSecondWidth] = useState("");
  const [showSecondSize, setShowSecondSize] = useState(false);
  const [offset, setOffset] = useState("");
  const [image, setImage] = useState<Blob | null>();
  const [details, setDetails] = useState("");
  const [quantity, setQuantity] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(false);

  useState(false);

  const styles = {
    border: "1px solid #fff",
    marginTop: 20,
  };

  const AddNewCustomWheel = async () => {
    setIsLoading(true);

    let link = "";
    if (image) {
      link = await Utils.getImageLink(image);
    }

    const company_id = TokenService.getUserId()!;

    let newCustomwheel = {
      brand,
      wheel_width: width,
      wheel_diameter: diameter,
      second_wheel_diameter: secondDiameter,
      second_wheel_width: secondWidth,
      quantity: quantity,
      second_bolt_pattern: secondBoltPattern,
      bolt_pattern: boltPattern,
      wheel_description: details,
      wheel_offset: offset,
      img: link,
      company_id,
    };

    WheelApiService.addCustomWheel(newCustomwheel)
      .then((res) => {
        if (res.status != 201) {
          setIsLoading(false);
          setErr(true);
          return;
        }
        setIsLoading(false);
        history.push("/success");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return isLoading ? (
    <div className="loading__overlay">
      <ReactLoading height={80} width={80} />
    </div>
  ) : (
    <div className="add__wheel__custom__container">
      <p className="add__wheel__oem__title">Add Custom Wheel</p>
      <div className="add__wheel__custom__form__container">
        <input
          maxLength={15}
          placeholder="Name Brand"
          className="add__wheel__custom__input"
          onChange={(ev) => setBrand(ev.target.value)}
        />

        <>
          <CustomButton
            styles={styles}
            color={"transparent"}
            onClickDo={() => setShowBoltPatterOverlay(true)}
            height={window.innerWidth > 900 ? 55 : 80}
            width={"100%"}
            borderRadius={10}
          >
            {boltPattern === "" ? "Bolt Pattern" : boltPattern}
          </CustomButton>
          {showSecondBoltPattern === false && (
            <div
              onClick={() => setShowSecondBoltPattern(true)}
              className="add__custom__wheel__add__second__bolt__pattern__container"
            >
              <IoMdAddCircle size={20} color={"#7272ec"} />
              <span>Add Second Bolt Pattern</span>
            </div>
          )}
        </>

        {showBoltPatternOverlay && (
          <Overlay
            list={W_CONTEXT!.boltPattern}
            show={showBoltPatternOverlay}
            onCloseBtn={() => setShowBoltPatterOverlay(false)}
            pickOption={(year: string) => {
              setBoltPattern(year);
              setShowBoltPatterOverlay(false);
            }}
          />
        )}
        {showSecondBoltPattern && (
          <>
            <CustomButton
              styles={styles}
              color={"transparent"}
              onClickDo={() => setShowSecondBoltPatternOverlay(true)}
              height={window.innerWidth > 900 ? 55 : 80}
              width={"100%"}
              borderRadius={10}
            >
              {secondBoltPattern === "" ? "Bolt Pattern" : secondBoltPattern}
            </CustomButton>

            <div
              onClick={() => setShowSecondBoltPattern(false)}
              className="add__custom__wheel__add__second__bolt__pattern__container"
            >
              <AiFillMinusCircle size={20} color={"#7272ec"} />
              <span>Close second Bolt Pattern</span>
            </div>
          </>
        )}
        {showSecondBoltPatternOverlay && (
          <Overlay
            list={W_CONTEXT!.boltPattern}
            show={showSecondBoltPatternOverlay}
            onCloseBtn={() => setShowSecondBoltPatternOverlay(false)}
            pickOption={(year: string) => {
              setSecondBoltPattern(year);
              setShowSecondBoltPatternOverlay(false);
            }}
          />
        )}

        <>
          {showSecondSize && (
            <p className="add__custom__wheel__size__title">Front Size</p>
          )}
          <div className="add__custom__wheel__size__container">
            <input
              maxLength={2}
              placeholder="Diameter"
              className="add__custom__wheel__size__input"
              onChange={(ev) => setDiameter(ev.target.value)}
            />
            <p style={{ color: "#fff", fontSize: 20 }}>X</p>
            <input
              maxLength={3}
              placeholder="Width"
              className="add__custom__wheel__size__input"
              onChange={(ev) => setWidth(ev.target.value)}
            />
          </div>
          {!showSecondSize && (
            <div
              onClick={() => setShowSecondSize(true)}
              className="add__custom__wheel__add__second__bolt__pattern__container"
            >
              <IoMdAddCircle size={20} color={"#7272ec"} />
              <span>Add Second Size</span>
            </div>
          )}
        </>

        {showSecondSize && (
          <>
            <p className="add__custom__wheel__size__title">Rear Size</p>
            <div className="add__custom__wheel__size__container">
              <input
                maxLength={2}
                placeholder="Diameter"
                className="add__custom__wheel__size__input"
                onChange={(ev) => setSecondDiameter(ev.target.value)}
              />
              <p style={{ color: "#fff", fontSize: 20 }}>X</p>
              <input
                maxLength={3}
                placeholder="Width"
                className="add__custom__wheel__size__input"
                onChange={(ev) => setSecondWidth(ev.target.value)}
              />
            </div>
            <div
              onClick={() => setShowSecondSize(false)}
              className="add__custom__wheel__add__second__bolt__pattern__container"
            >
              <AiFillMinusCircle size={20} color={"#7272ec"} />
              <span>Show only one size</span>
            </div>
          </>
        )}

        <input
          maxLength={3}
          placeholder="Offset"
          className="add__wheel__custom__input"
          onChange={(ev) => setOffset(ev.target.value)}
        />
        <input
          maxLength={2}
          placeholder="Quantity"
          className="add__wheel__custom__input"
          onChange={(ev) => setQuantity(ev.target.value)}
        />
        <div className="add__wheel__custom__details__container">
          <textarea
            onChange={(ev) => setDetails(ev.target.value)}
            name="details"
            placeholder="Details"
            className="add__custom__wheel__description"
          />
        </div>

        <div
          className="add__custom__Wheel__image__container"
          style={!image ? styles : { border: 1 }}
        >
          {!image ? (
            <input
              className="add__Wheel__oem__picker"
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setImage(e.target.files![0]);
              }}
            />
          ) : (
            <>
              <AiFillCloseCircle
                onClick={() => setImage(null)}
                className="add__wheel__oem__close__btn"
                size={30}
              />
              <img
                alt="custom-wheel"
                className="add__wheel__oem__image"
                height={100}
                src={URL.createObjectURL(image)}
              />
            </>
          )}
        </div>
      </div>

      <CustomButton
        width={"90%"}
        height={80}
        borderRadius={10}
        color={"transparent"}
        styles={{ ...styles, marginBottom: 20 }}
        onClickDo={() => AddNewCustomWheel()}
      >
        Post
      </CustomButton>
    </div>
  );
};

export default AddCustomWheel;
