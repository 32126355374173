import { FC } from "react";
import "./ResultBlock.css";
import { useHistory } from "react-router-dom";

interface ResultBlockProps {
  type: string
  id: string
  companyId: string
  imageSrc: string
  title: string
  description: string
}

const ResultBlock: FC<ResultBlockProps> = (props) => {
  const history = useHistory();

  return (
    <div
      onClick={() =>
        history.push(`/items/${props.type}/${props.id}`)
      }
      className="result__block__container"
    >
      <div className="result__block__image__container">
        <img
          alt="item"
          className="result__block__image"
          width={"100%"}
          src={props.imageSrc}
        />
      </div>
      <div className="result__block__info__container">
        <p style={{ color: "white" }}>{props.title}</p>
        <p style={{ color: "white" }}>{props.description}</p>
      </div>
    </div>
  );
};

export default ResultBlock;
