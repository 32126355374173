import { FC } from "react"
import { Logo } from "../../common/Logo"
import { Typography } from "../../common/Typography"
import { Container } from "../../common/Container"
import { Section } from "../../common/Section"
import styles from "./LoginHeader.module.scss"

export const LoginHeader: FC = () => {
    return (
        <Section className={styles["login-header"]} >
            <Logo size="large" />
            <Container
                className={styles["login-header-text"]}
                justifyContent="space-around"
                align="left"
                orientation="vertical">
                <Typography type="h1"> Welcome Back!</Typography>
                <Typography type="h3"> Log in to your account.</Typography>
            </Container>
        </Section>
    )
}