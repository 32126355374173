import { useState } from "react";
import Avatar from "react-avatar";
import CustomWheelImg from "../../images/customWheel.png";
import OEMWheelImg from "../../images/oemWheel.png";
import TireImg from "../../images/tire.png";
// import TokenService from "../../Services/token-service";
import { useHistory } from "react-router";
import WheelApiService from "../../Services/wheel-api-service";
//new

import { AppPage } from "../../common/AppPage";
import { ItemCard } from "./ItemCard";
import { ScanWidget } from "./ScanWidget";
import { Section } from "../../common/Section";
import { Container } from "../../common/Container"
import { TokenService } from "../../Services/token.service";
import styles from "./index.module.scss"
import { Typography } from "../../common/Typography";

export const HomePage = () => {
  const history = useHistory();

  const [showScan, setShowScan] = useState(false);
  const [scanId, setScanId] = useState("");
  const [err, setErr] = useState(true);
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onChangeScanInput = (input: string) => {
    setErr(false);
    setScanId(input);
  }

  const scanOemWheel = () => {
    let barcode = scanId;

    setIsLoading(true);
    setMsg("");
    setErr(false);

    if (barcode.length < 13) {
      setIsLoading(false);
      setMsg("Please scan or type a valid input");
      setErr(true);

      return;
    }

    if (barcode.includes("-")) {
      WheelApiService.scanOemWheel(barcode).then((response) => {
        if (response.length < 1) {
          setIsLoading(false);
          setErr(true);
          setMsg("We could not find any item with that code");
          return;
        }
        setIsLoading(false);
        history.push(
          `/items/oemWheels/${response[0].id}`
          // `/items/oemWheels/${response[0].id}/${response[0].company_id}`

        );
      });
    } else {
      WheelApiService.scanOemWheel(
        `${TokenService.getCompanyId()}-${barcode}`
      ).then((response) => {
        if (response.length < 1) {
          setIsLoading(false);
          setErr(true);
          setMsg("We could not find any item with that code try again");
          return;
        }
        setIsLoading(false);
        history.push(
          `/items/oemWheels/${response[0].id}`
        );
      });
    }
  };

  return (
    <AppPage className={styles.inventory} >
      <Section className={styles["inventory-section"]}  >
        <Container align="center" orientation="vertical">
          <Avatar name={TokenService.getCompanyName()!} size="80" round={true} />
          <Typography type="h2">{TokenService.getCompanyName()}</Typography>
        </Container>
        <ScanWidget
          scanOemWheel={scanOemWheel}
          onChange={onChangeScanInput}
          onClick={setShowScan}
          showScan={showScan}
          isLoading={isLoading}
        />
        <Container className={styles["inventory-cards"]} align="center" orientation="vertical" >
          <ItemCard
            onClick={() => history.push("/inventory/tires")}
            label="Tires"
            imageSrc={TireImg} />
          <ItemCard
            onClick={() => history.push("/inventory/oemwheels")}
            label="OEM Wheels"
            imageSrc={OEMWheelImg} />
          <ItemCard
            onClick={() => history.push("/inventory/customwheels")}
            label="Custom Wheels"
            imageSrc={CustomWheelImg} />
        </Container>
      </Section>
    </AppPage>
  );
};

