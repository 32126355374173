import { Section } from "../../common/Section"
import { Form } from "../../common/Form"
import { FormInput } from "../../components/FormInput/FormInput"
import { FC } from "react"
import { Button } from "../../common/Button"
import { Loading } from "../../common/Loading"

interface ICompanyInfoFormProps {
    setOrganizationName: (name: string) => void
    setOrganizationAddress: (name: string) => void
    setOrganizationCity: (name: string) => void
    setOrganizationState: (name: string) => void
    setOrganizationZipcode: (name: string) => void
    setOrganizationPhone: (name: string) => void
    setOrganizationTaxPayerNumber: (name: string) => void
    createOrganization: () => void
    isLoading: boolean
}

export const CompanyInfoForm: FC<ICompanyInfoFormProps> = ({
    setOrganizationName,
    setOrganizationAddress,
    setOrganizationCity,
    setOrganizationState,
    setOrganizationZipcode,
    setOrganizationPhone,
    setOrganizationTaxPayerNumber,
    createOrganization,
    isLoading

}) => {
    return (
        <Section>
            <Form onSubmit={createOrganization}>
                <FormInput placeholder="Company Name" name="company_name" orientation="vertical" onChange={setOrganizationName} />
                <FormInput placeholder="Address" name="company_address" orientation="vertical" onChange={setOrganizationAddress} />
                <FormInput placeholder="City" name="company_city" orientation="vertical" onChange={setOrganizationCity} />
                <FormInput placeholder="State" name="company_state" orientation="vertical" onChange={setOrganizationState} />
                <FormInput placeholder="Zip Code" name="company_zip_code" orientation="vertical" onChange={setOrganizationZipcode} maxLength={10} />
                <FormInput placeholder="Phone Number" name="company_phone" orientation="vertical" onChange={setOrganizationPhone} maxLength={14} />
                <FormInput placeholder="Tax Payer Number" name="company_tax" orientation="vertical" onChange={setOrganizationTaxPayerNumber} maxLength={13} />
                {isLoading ? <Loading /> : <Button submitButton size="large" type="primary">
                    Next
                </Button>}
            </Form>
        </Section>
    )
}