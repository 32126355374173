import React, { useState, useEffect, FC, useContext } from "react";
import "./ResultsPage.css";
import ResultBlock from "../../components/ResultBlock/ResultBlock";
import Pagination from "../../components/Pagination/Pagination";
import { useParams, useLocation } from "react-router";
import TiresApiService from "../../Services/tires-api-service";
import WheelApiService from "../../Services/wheel-api-service";
import ReactLoading from "react-loading";
// import TokenService from "../../Services/token-service";
//New imports
import { TokenService } from "../../Services/token.service";
import { StoreContext } from "../../store.context";
import { AppPage } from "../../common/AppPage";
import { observer } from "mobx-react-lite";
import { Box } from "@mui/material";
import styles from "./ResultsPage.module.scss";
import { Section } from "../../common/Section";
interface IParamsProps {
  partType: "tires" | "oemwheels" | "customwheels";
}

const ResultsPage: FC = observer(() => {
  const { partType } = useParams<IParamsProps>();
  const { tireStore, customWheelStore, oemWheelStore } =
    useContext(StoreContext);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();

  const [data, setData] = useState([]);
  const [perPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const getTires = () => {
    if (query.get("myStore") === "false") {
      tireStore.queryTiresBySize(query.get("tireSize")!);
      return;
    }
    tireStore.getMyOwnTiresBySize(query.get("tireSize")!);
    return;
  };

  const getCustomWheels = () => {
    WheelApiService.searchCustomWheel(
      query.get("diameter")!,
      query.get("width")!,
      query.get("boltPattern")!
    ).then((response) => {
      setData(response);
    });
  };

  const getOemWheels = () => {
    if (TokenService.getCompanyId() === query.get("companyId")!) {
      oemWheelStore.getMyOwnWheelsByInterchangeNumber(
        query.get("interchange")!
      );
      return;
    }
    oemWheelStore.searchWheelsByModelAndSize(
      query.get("make")!,
      query.get("diameter")!,
      query.get("model")!,
      query.get("width")!
    );
    WheelApiService.searchOemWheel(
      query.get("make")!,
      query.get("model")!,
      query.get("width")!,
      query.get("diameter")!
    ).then((response) => {
      setData(response);
    });
  };

  const populateResults = () => {
    let startDatafrom = (currentPage - 1) * perPage;
    let endDataAt = currentPage * perPage;

    let it: any[] = [];

    if (partType === "tires") {
      it = tireStore.myTiresBySize.map(
        (
          tire: {
            id: string;
            size: string;
            brand: string;
            details: string;
            img: string;
            company_id: string;
          },
          idx
        ) => (
          <ResultBlock
            key={tire.id}
            id={tire.id}
            type={"tires"}
            title={`${tire.size} ${tire.brand}`}
            description={tire.details}
            imageSrc={tire.img}
            companyId={tire.company_id}
          />
        )
      );
    }

    if (partType === "oemwheels") {
      console.log("here");
      it = oemWheelStore.myOEMWheelsByInterchange.map(
        (
          wheel: {
            id: string;
            make: string;
            model: string;
            wheel_diameter: string;
            wheel_width: string;
            wheel_description: string;
            img: string;
            company_id: string;
          },
          idx
        ) => (
          <ResultBlock
            key={idx}
            id={wheel.id}
            type={"oemWheels"}
            title={`${wheel.make} ${wheel.model} ${wheel.wheel_diameter} X ${wheel.wheel_width} `}
            description={wheel.wheel_description}
            imageSrc={wheel.img}
            companyId={wheel.company_id}
          />
        )
      );
    }

    if (partType === "customwheels") {
      it = data.map(
        (
          wheel: {
            id: string;
            brand: string;
            wheel_diameter: string;
            wheel_width: string;
            wheel_description: string;
            img: string;
            company_id: string;
          },
          idx
        ) => (
          <ResultBlock
            key={idx}
            id={wheel.id}
            type={"customWheels"}
            title={`${wheel.brand} ${wheel.wheel_diameter} X ${wheel.wheel_width}`}
            description={wheel.wheel_description}
            imageSrc={wheel.img}
            companyId={wheel.company_id}
          />
        )
      );
    }

    return it.slice(startDatafrom, endDataAt);
  };

  useEffect(() => {
    setIsLoading(true);
    if (partType === "tires") {
      getTires();
    }
    if (partType === "oemwheels") {
      getOemWheels();
    }
    if (partType === "customwheels") {
      getCustomWheels();
    }
    populateResults();
    setIsLoading(false);
  }, []);

  return (
    <AppPage>
      <Section className={styles["results-page"]}>
        <div className="result__page__header__container">
          {!isLoading && partType === "tires" && (
            <>
              <p className="results__page__header">
                Results for:{" "}
                {`${query.get("tireSize")!.slice(0, 3)}-${query
                  .get("tireSize")!
                  .slice(3, 5)} / ${query.get("tireSize")!.slice(5)}`}
              </p>
              <br></br>
              <p style={{ color: "#fff", fontWeight: 500 }}>
                Quantity: {data.length ?? "-"}
              </p>{" "}
            </>
          )}
          {!isLoading && partType === "oemwheels" && (
            <>
              <p className="results__page__header">
                Results for:{" "}
                {query.get("interchange")
                  ? `560-${query.get("interchange")}`
                  : `${query.get("make")}, ${query.get("diameter")}"`}
              </p>
              <br></br>
              <p style={{ color: "#fff", fontWeight: 500 }}>
                Quantity: {data.length}
              </p>{" "}
            </>
          )}
        </div>
        <div className="results__page__cards__container">
          {isLoading && <ReactLoading height={80} width={80} />}
          {populateResults()}
          {((partType === "tires" && tireStore.myTiresBySize.length < 1) ||
            (partType === "oemwheels" &&
              oemWheelStore.myOEMWheelsByInterchange.length < 1)) && (
            <Box
              sx={{
                width: "100%",
              }}
            >
              <div className="results__page__no__results__container">
                <h2 style={{ color: "#fff" }}>
                  There are no results for your search.
                </h2>
              </div>
            </Box>
          )}
          <Pagination
            size={Math.ceil(data.length / perPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </Section>
    </AppPage>
  );
});

export default ResultsPage;
