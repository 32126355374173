import { FC } from "react"
import { Loading } from "../../common/Loading"
import { BiBarcodeReader } from "react-icons/bi";
import { Container } from "../../common/Container"
import { Typography } from "../../common/Typography"
import styles from "./ScanWidget.module.scss"

interface IScanWidgetProps {
  showScan: boolean
  onChange: (ev: string) => void
  onClick: (_: boolean) => void
  isLoading: boolean
  scanOemWheel: () => void
}

export const ScanWidget: FC<IScanWidgetProps> = ({
  showScan,
  onChange,
  onClick,
  scanOemWheel,
  isLoading
}) => {
  return (<>
    {showScan && (
      <Container className={styles["scan-widget"]} orientation="horizontal">
        <Typography type="h4">Scan</Typography>
        <input
          onChange={(ev) => onChange(ev.target.value)}
          placeholder="123-45-678"
        />
        <button onClick={scanOemWheel} >
          Search
        </button>
      </Container>
    )}
    <Container orientation="vertical" className={styles["barcode-icon"]}>
      {isLoading ? (
        <Loading />
      ) : (
        <BiBarcodeReader
          className="home__scan"
          size={25}
          onClick={() => onClick(!showScan)}
          color="#fff"
        />
      )}
    </Container >
  </>)
}