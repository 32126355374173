import React, { useState, useEffect, useContext, FC } from "react";
import "./AddPage.css";

import ButtonContainer from "../../components/ButtonContainer/ButtonContainer";

import AddTireComponent from "../../components/AddTireComponent/AddTireComponent";
import AddWheelComponent from "../../components/AddWheelComponent/AddWheelComponent";

import WheelContext from "../../Context/WheelContext";
import TiresApiService from "../../Services/tires-api-service";
import WheelApiService from "../../Services/wheel-api-service";

import { useParams } from "react-router";

//new Imports 

import { AppPage } from "../../common/AppPage"
import { Button, TextField } from "@mui/material"

interface AddPageProps {
  partType: string
}

const AddPage: FC = () => {
  // const T_CONTEXT = useContext(TireContext);
  const W_CONTEXT = useContext(WheelContext);

  const { partType } = useParams<AddPageProps>()

  // const getTireParameters = () => {
  //   TiresApiService.getTireParameters().then((res) => {
  //     const { width, ratio, diameter } = res;
  //     T_CONTEXT!.setTireOptions(width, ratio, diameter);
  //   });
  // };

  const getWheelPArameters = () => {
    WheelApiService.getWheelParameters().then((res) => {
      const { carMake, carModel, years, boltPatter } = res;
      W_CONTEXT!.setWheelParameters(carMake, carModel, years, boltPatter);
    });
  };

  useEffect(() => {
    if (partType === "tires") {
      setActive("tires")
    }
    if (partType === "customwheels" || partType === "oemWheels") {
      setActive("wheels")
    }
    // getTireParameters();
    getWheelPArameters();
  }, []);
  const [active, setActive] = useState("");

  return (
    <AppPage >
    <div className="add__page">
     
      <ButtonContainer setActive={setActive} active={active} />
      {active === "tires" && <AddTireComponent />}
      {active === "wheels" && <AddWheelComponent />}
    </div>
    </AppPage>
  );
};

export default AddPage;
