import { config } from "../config";
import { TokenService } from "./token.service";

export class CustomWheelService {
  getMyCustomWheels = async () => {
    const response = await fetch(`
        ${config.API_ENDPOINT}/customwheels/all?company_id=${TokenService.getCompanyId()}`);
    const jsonResponse = await response.json();
    return jsonResponse;
  };
}
