import { FC } from "react"

interface IBackArrowProps {
    color?: string
    size: number
    className?: string
    onClick?: () => void
}

export const BackArrow: FC<IBackArrowProps> = ({
    color, size, className, onClick
}) => {
    return (
        <svg
            onClick={onClick}
            viewBox="0 0 24 24"
            color={color}
            className={className}
            height={size} width={size}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M21 11L6.414 11 11.707 
            5.707 10.293 4.293 2.586 12 10.293 
            19.707 11.707 18.293 6.414 13 21 13z">
            </path>
        </svg>
    )
}