import React, { useContext, FC } from "react";
import "./SignUpPage.css";
import { observer } from "mobx-react-lite";
import { Page } from "../../common/Page";
import { Logo } from "../../common/Logo";
import { StoreContext } from "../../store.context";
import { CompanyInfoForm } from "./CompanyForm";
import styles from "./index.module.scss"
import { ErrorMsg } from "../../components/ErrorMsg/ErrorMsg";
import { ManagerInfoForm } from "./ManagerInfoForm";

interface ISignUpPageProps {
  onAccountCreated: () => void
}


export const SignUpPage: FC<ISignUpPageProps> = observer(({ onAccountCreated }) => {

  const { organizationStore, userStore } = useContext(StoreContext)

  return (
    <Page className={styles["signup-page"]}>
      <Logo size="large" />
      {organizationStore.organizationCreated ? (
        <ManagerInfoForm
          isLoading={userStore.isLoading}
          onSubmit={onAccountCreated}
          setPassword={userStore.setPassword}
          setUserEmail={userStore.setUserEmail}
          setUserName={userStore.setUserName}
          setConfirmPassword={userStore.setConfirmPassword}
        />
      ) : (
        <CompanyInfoForm
          isLoading={organizationStore.isLoading}
          createOrganization={organizationStore.createOrganization}
          setOrganizationName={organizationStore.setOrganizationName}
          setOrganizationAddress={organizationStore.setOrganizationAddress}
          setOrganizationCity={organizationStore.setOrganizationCity}
          setOrganizationState={organizationStore.setOrganizationState}
          setOrganizationZipcode={organizationStore.setOrganizationZipcode}
          setOrganizationPhone={organizationStore.setOrganizationPhoneNumber}
          setOrganizationTaxPayerNumber={organizationStore.setOrganizationTaxNumber}
        />
      )}
      <ErrorMsg messsage={organizationStore.error || userStore.error} />
    </Page>
  );
})

