import { Section } from "../../common/Section"
import { Typography } from "../../common/Typography"
import { FormInput } from "../../components/FormInput/FormInput"
import { Button } from "../../common/Button"
import { FC } from "react"
import { Container } from "../../common/Container"
import styles from "./index.module.scss"
import { Loading } from "../../common/Loading"

interface IInputResetCodeProps {
    setCode: (_: string) => void
    verifyCode: () => void,
    isLoading: boolean
}

export const InputResetCode: FC<IInputResetCodeProps> = ({
    setCode,
    verifyCode,
    isLoading
}) => {
    return (
        <Section>
            <Container className={styles["reset-box"]} align="left" orientation="vertical">
                <Typography type="h2">
                    Type code.
                </Typography><br />
                <Typography type="h4">
                    If the email was valid you should have received a code.
                </Typography>
            </Container>
            <FormInput name="reset-code" orientation="vertical" onChange={setCode} />
            {isLoading ? <Loading /> : <Button
                onClick={verifyCode}
                type="primary"
                size="large">
                Confirm
            </Button>}
        </Section>
    )
}