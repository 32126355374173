import { config } from "../config";
import { TokenService } from "./token.service";

export class OEMWheelService {
  getMyOEMWheels = async (): Promise<any[]> => {
    const response = await fetch(
      `${config.API_ENDPOINT
      }/oemwheels/all?company_id=${TokenService.getCompanyId()}`
    );
    const jsonResponse = await response.json();
    return jsonResponse;
  };
  getMyOEMWheelByInterchangeNumber = async (interchange: string) => {
    const response = await fetch(
      `${config.API_ENDPOINT}/oemWheels/interchange?interchange=560-${interchange}&companyId=${TokenService.getCompanyId()}`
    );
    const jsonResponse = await response.json();
    return jsonResponse;
  };
  queryOEMWheelsByModelAndSize = async (make: string, diameter: string, model: string, width: string) => {
    const response = await fetch(`${config.API_ENDPOINT
      }/oemwheels/search?make=${make}&model=${model}&width=${width}&diameter=${diameter}&companyId=${TokenService.getCompanyId()}`)

    const jsonResponse = await response.json()
    return jsonResponse
  }
}
