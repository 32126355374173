import { FC } from "react";
import { useHistory } from "react-router";

import { Button } from "../../common/Button";
import { Page } from "../../common/Page"
import { Logo } from "../../common/Logo"
import { Section } from "../../common/Section"
import { Container } from "../../common/Container"
import { Typography } from "../../common/Typography"
import styles from "./SuccessPage.module.scss"

interface ISuccessPageProps {
  route: string
  msg: string
  btnTitle: string
}

const SuccessPage: FC<ISuccessPageProps> = (props) => {
  const history = useHistory();
  return (
    <Page className={styles.success}>
      <Section >
        <Logo size="large" />
        <Container 
        justifyContent="space-around"
        className={styles["success-msg"]} orientation="vertical">
          <Typography type="h3" >
            {props.msg}
          </Typography>
          <Button onClick={() => history.push(props.route)} type="primary" size="large">
            {props.btnTitle}
          </Button>
        </Container>
      </Section>
    </Page>
  );
};

export default SuccessPage;
