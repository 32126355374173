import styles from "./Container.module.scss"
import { FC, ReactNode } from "react"

interface IContainerProps {
    onClick?: () => void
    children: ReactNode
    orientation: "horizontal" | "vertical"
    className?: string
    align?: "left" | "center" | "right"
    justifyContent?: "space-evenly" | "space-between" | "space-around" | "center"
    
}

export const Container: FC<IContainerProps> = ({ children, orientation, align, className, justifyContent, onClick }) => {
    return (
        <div
            onClick={onClick}
            className={` 
            ${styles.container} 
            ${styles[`container-${align}`]}  
            ${styles[`container-${orientation}`]} 
            ${styles[`container-${justifyContent}`]} 
            ${className}`}>
            {children}
        </div>
    )
}