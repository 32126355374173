import React, { useState, useEffect, FC, useContext, useLayoutEffect } from "react";
import "./EditCompanyInfo.css";
import CustomButton from "../../components/CustomButton/CustomButton";
import ReactLoading from "react-loading";
import CompanyApiService from "../../Services/company-api-service";
import { useHistory } from "react-router";

//New Imports

import { AppPage } from "../../common/AppPage";
import { TextField, Button, Typography } from "@mui/material";
import { StoreContext } from "../../store.context";
import { TokenService } from "../../Services/token.service";
import { observer } from "mobx-react-lite"


const EditCompanyInfo: FC = observer(() => {

  const { organizationStore } = useContext(StoreContext)

  const [err, setErr] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useLayoutEffect(() => {
    organizationStore.getOrganizationsDetails(TokenService.getCompanyId()!)
  }, []);

  // const updateInfo = () => {
  //   setIsLoading(true);
  //   CompanyApiService.updateCompanyInfo({
  //     company_name: companyName,
  //     company_address: companyAddress,
  //     company_city: companyCity,
  //     company_state: companyState,
  //     company_zipcode: companyZipCode,
  //     company_phonenumber: companyPhoneNumber,
  //     companyId: TokenService.getCompanyId()!,
  //   }).then(() => {
  //     setIsLoading(false);
  //     history.push("/settings")
  //   });
  // };

  return (
    <AppPage>
      <div className="edit__company__info__page">

        <h3
          className="edit__company__title"
          style={{ color: "#fff", marginTop: 25, marginBottom: 20 }}
        >
          Company Information
        </h3>
        <form
          onSubmit={() => { }}
          autoComplete="off"
          className="signup__form__holder"
        >

          <TextField
            label="Company name"
            variant="outlined"
            onChange={(ev) => organizationStore.setOrganizationName(ev.target.value)}
            value={organizationStore.organizationName}
            name="company_name"
            autoComplete="off"
            sx={{
              width: "100%",
              marginBottom: 2
            }}
          />
          <TextField
            label="Address"
            variant="outlined"
            onChange={(ev) => organizationStore.setOrganizationAddress(ev.target.value)}
            value={organizationStore.organizationAddress}
            name="company_address"
            autoComplete="off"
           
            sx={{
              width: "100%",
              marginBottom: 2
            }}
          />
          <TextField
            label="City"
            variant="outlined"
            onChange={(ev) => organizationStore.setOrganizationCity(ev.target.value)}
            value={organizationStore.organizationCity}
            name="company_city"
            autoComplete="off"
            sx={{
              width: "100%",
              marginBottom: 2
            }}
          />
          <TextField
            label="State"
            variant="outlined"
            onChange={(ev) => organizationStore.setOrganizationState(ev.target.value)}
            value={organizationStore.organizationState}
            name="company_state"
            autoComplete="off"
            sx={{
              width: "100%",
              marginBottom: 2
            }}
          />

          <TextField
            label="Zip code"
            variant="outlined"
            onChange={(ev) => organizationStore.setOrganizationZipcode(ev.target.value)}
            value={organizationStore.organizationZipcode}
            name="company_zipcode"
            autoComplete="off"
            sx={{
              width: "100%",
              marginBottom: 2
            }}
          />

          <TextField
            label="Phone number"
            variant="outlined"
            onChange={(ev) => organizationStore.setOrganizationPhoneNumber(ev.target.value)}
            value={organizationStore.organizationPhone}
            name="company_phonenumber"
            autoComplete="off"
            sx={{
              width: "100%",
              marginBottom: 2
            }}
          />


          {err && (
            <div className="error__container">
              <p>{errorMsg}</p>
            </div>
          )}
          {isLoading ? (
            <ReactLoading height={50} width={50} />
          ) : (
            <Button variant="contained" size="large"
              sx={{
                width: "100%",
                marginTop: 3
              }}
            >
              Update
            </Button>
          )}
        </form>
      </div>
    </AppPage>
  )
})

export default EditCompanyInfo;
