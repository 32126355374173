import { FC, useContext } from "react";
import styles from "./index.module.scss";
import { useHistory } from "react-router";
import { Page } from "../../common/Page"
import { BackArrow } from "../../common/icons/BackArrow";
import { SendCode } from "./SendCode";
import { StoreContext } from "../../store.context";
import { InputResetCode } from "./InputResetCode";
import { ErrorMsg } from "../../components/ErrorMsg/ErrorMsg";
import { observer } from "mobx-react-lite";
import { InputNewPassword } from "./InputNewPassword";

export const ResetPassword: FC = observer(() => {
  const history = useHistory();

  const { userStore } = useContext(StoreContext)

  const onGoBack = () => {
    userStore.setResetPasswordState("sendCode")
    history.push("/")
  }

  const onResetPassword = async () => {
    const resetStatus = await userStore.resetPassword()
    if (resetStatus) {
      history.push("/")
    }
  }


  return (
    <Page className={styles["reset-page"]}>
      <BackArrow
        onClick={onGoBack}
        className={styles["reset-page-back-arrow"]}
        size={50} />
      {userStore.resetPasswordState === "sendCode" && (
        <SendCode
          setEmail={userStore.setUserEmail}
          sendResetCode={userStore.sendResetCode}
          isLoading={userStore.isLoading}
        />
      )}{
        userStore.resetPasswordState === "enterCode" &&
        (<InputResetCode
          isLoading={userStore.isLoading}
          verifyCode={userStore.verifyCode}
          setCode={userStore.setResetCode} />)
      }
      {userStore.resetPasswordState === "enterNewPassword" &&
        <InputNewPassword
          isLoading={userStore.isLoading}
          setPassword={userStore.setPassword}
          setConfirmPassword={userStore.setConfirmPassword}
          resetPassword={onResetPassword}
        />
      }
      <ErrorMsg messsage={userStore.error} />
    </Page>
  );
});

