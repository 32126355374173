import { CSSProperties, FC, ReactNode } from "react";
import "./Card.css";

interface CardProps {
  onClickDo: () => void,
  style?: CSSProperties,
  height: string,
  width: string,
  children: ReactNode
}

const Card: FC<CardProps> = ({
  onClickDo,
  style,
  height,
  width,
  children
}) => {
  return (
    <div
      onClick={onClickDo}
      className="card"
      style={{
        ...style,
        ...{ height: height, width: width },
      }}
    >
      {children}
    </div>
  );
};

export default Card;
