import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./Context/UserProvider";
import { WheelProvider } from "./Context/WheelContext";
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import CssBaseline from "@mui/material/CssBaseline";
import { darkTheme, defaultTheme } from "./Theme/theme";
import { createTheme } from "@mui/material/styles";

const muiTheme = createTheme(darkTheme)

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ThemeProvider theme={muiTheme}>
    <CssBaseline>
      <UserProvider>
        <WheelProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
        </WheelProvider>
      </UserProvider>
    </CssBaseline>
  </ThemeProvider>
)

