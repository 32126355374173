import { config } from "../config";
import { CreateUserRequest } from "../data-transfer-objects/request/create-user.dtc";

export class UserService {
  createUser = async (createUserRequest: CreateUserRequest) => {
    const response = await fetch(`${config.API_ENDPOINT}/users`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(createUserRequest),
    });
    const parsedResponse = await response.json();
    if (!response.ok) {
      throw new Error(parsedResponse as string);
    }
    return parsedResponse;
  };
  sendResetCode = async (_: string) => {
    const response = await fetch(
      `${config.API_ENDPOINT}/users/forgotPassword`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ email: _ }),
      }
    );

    return response;
  };

  verifyCode = async (email: string, code: string) => {
    const response = await fetch(
      `${config.API_ENDPOINT}/users/verifyCode?email=${email}&userCode=${code}`
    );
    console.log(response);
    const parsedResponse = await response.json();
    if (!response.ok) {
      throw new Error(parsedResponse as string);
    }
    return parsedResponse;
  };
  resetPassword = async (email: string, password: string) => {
    const response = await fetch(`${config.API_ENDPOINT}/users/resetPassword`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ email: email, newPassword: password }),
    });
    const parsedResponse = await response.json();
    if (!response.ok) {
      throw new Error(parsedResponse as string);
    }
    return parsedResponse;
  };
}
