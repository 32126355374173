import React, { useState, useEffect, CSSProperties, useContext } from "react";
import "./AddTireComponent.css";
import TiresApiService from "../../Services/tires-api-service";
import { AiFillCloseCircle } from "react-icons/ai"
import ReactLoading from "react-loading";
import { useHistory, useParams } from "react-router";
// import TokenService from "../../Services/token-service";
import Utils from "../../Utils/Utils";

//New Imports

import { Typography } from "../../common/Typography"
import { Container } from "../../common/Container"
import { TextField, Button } from "@mui/material"
import { TokenService } from "../../Services/token.service"
import { StoreContext } from "../../store.context"
import { TireModel } from "../../Model/tire";
import { config } from "../../config"

type tireParams = {
  itemId: string
}

const AddTireComponent: React.FC = () => {
  const history = useHistory();
  const { itemId } = useParams<tireParams>();
  const { tireStore } = useContext(StoreContext)

  useEffect(() => {
    if (itemId) {
      TiresApiService.getTireById(itemId).then((response) => {
        setEditImage(response[0].img);
        setImage("test")
        setQuantity({ value: response[0].quantity, err: false });
        setCondition(response[0].condition);
        setBrand({ value: response[0].brand, err: false });
        setDescription({ value: response[0].details, err: false });
        setSize({ value: response[0].size, err: false })
        console.log(response)
      });
    }
  }, [itemId]);

  const [editImage, setEditImage] = useState()
  const [image, setImage] = useState<Blob | null | string>();
  const [condition, setCondition] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [brand, setBrand] = useState({
    value: "",
    err: false
  });
  const [quantity, setQuantity] = useState({
    value: "",
    err: false
  });
  const [description, setDescription] = useState({
    value: "",
    err: false
  });
  const [size, setSize] = useState({
    value: "",
    err: false
  })


  const styles: CSSProperties = {
    border: " 1px solid",
    fontSize: "25px",
    marginTop: "10px",
    marginBottom: "10px",
  };

  //////////////ADD NEW TIRE FUNCTION /////////////////////////

  const addNewTire = async () => {
    setIsLoading(true);
    let link = config.DEFAULT_TIRE_IMAGE;

    if (size.value === undefined || size.value.length < 5) {
      setSize({
        ...size,
        err: true
      })
      setIsLoading(false)
      return
    }
    if (image && image !== "test") {
      link = await Utils.getImageLink(image as Blob);
    }


    let newTire = {
      brand: brand.value,
      size: size.value,
      quantity: quantity.value,
      condition: "used",
      details: "used",
      img: link,
      company_id: TokenService.getCompanyId(),
    };

    const result = await tireStore.postTire(newTire as TireModel)

    if (result.error) {
      setIsLoading(false)
      return
    }

    console.log("here", result)
    setIsLoading(false)
    history.push("/success");
  };

  return (
    <div className="add__tire__container">
      {isLoading ? (
        <div className="loading__overlay">
          <ReactLoading height={80} width={80} />
        </div>
      ) : (
        <>
          <Typography type="h2">Add Tire(s)</Typography>
          <Container orientation="vertical" >

            <TextField
              value={size.value}
              sx={{ marginTop: 1, marginBottom: 1 }}
              label="Tire size" variant="outlined"
              onChange={(ev) => setSize({ value: ev.target.value, err: false })}
              error={size.err}
              helperText="Only numbers"
            />
            <TextField
              value={brand.value}
              sx={{ marginTop: 1, marginBottom: 1 }}
              label="Brand"
              variant="outlined"
              onChange={(ev) => setBrand({ ...brand, value: ev.target.value })}
              color={"primary"}
            />
            <TextField
              value={quantity.value}
              sx={{ marginTop: 1, marginBottom: 1 }}
              label="Quantity"
              variant="outlined"
              onChange={ev => setQuantity({ ...quantity, value: ev.target.value })}
            />
            <TextField
              value={description.value}
              sx={{ marginTop: 1, marginBottom: 1 }}
              label="Description"
              variant="outlined"
              onChange={(ev) => setDescription({ ...description, value: ev.target.value })}
            />
          </Container >
          <div className="add__tire__component">
          </div>
          <div
            className="add__tire__image__container"
            style={!image ? { ...styles, borderColor: "#fff" } : { border: 1 }}
          >
            {!image ? (
              <input
                className="add__tire__picker"
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setImage(e.target.files![0]);
                }}
              />
            ) : (
              <>
                <AiFillCloseCircle
                  onClick={() => setImage(null)}
                  className="add__tire__close__btn"
                  size={30}
                />
                <img
                  alt="tire-preview"
                  className="add__tire__image"
                  height={100}
                  src={itemId !== undefined ? editImage : URL.createObjectURL(image as Blob)}
                />
              </>
            )}
          </div>
          {isLoading ? (
            <ReactLoading height={50} width={50} />
          ) : (
            <Button
              size="large"
              variant={"contained"}
              sx={{ width: "100%", marginTop: 1, marginBottom: 2 }}
              onClick={addNewTire}
            >Complete
            </Button>

          )}
        </>
      )}
    </div>
  );
};

export default AddTireComponent;
