import { Section } from "../../common/Section"
import { Typography } from "../../common/Typography"
import { Button } from "../../common/Button"
import { Container } from "../../common/Container"
import { FormInput } from "../../components/FormInput/FormInput"
import styles from "./index.module.scss"
import { FC } from "react"
import { Loading } from "../../common/Loading"
interface IInputNewPasswordProps {
    setPassword: (_: string) => void
    setConfirmPassword: (_: string) => void
    resetPassword: () => void
    isLoading: boolean
}

export const InputNewPassword: FC<IInputNewPasswordProps> = ({
    setPassword,
    setConfirmPassword,
    resetPassword,
    isLoading
}) => {
    return (
        <Section>
            <Container align="left" className={styles["reset-box"]} orientation="vertical">
                <Typography type="h2">
                    Enter new password.
                </Typography>
            </Container>
            <FormInput
                type="password"
                placeholder="New password"
                onChange={setPassword}
                name="password" />
            <FormInput
                type="password"
                placeholder="Confirm new password"
                onChange={setConfirmPassword}
                name="confirm-password" />
            {isLoading ?
                <Loading />
                : <Button
                    type="primary"
                    size="large"
                    onClick={resetPassword} >
                    Confirm
                </Button>
            }
        </Section>
    )
}