import { FC } from "react";
import { Container } from "../../common/Container";
import styles from "./Option.module.scss"
import { Link, useHistory } from "react-router-dom"

interface IOptionProps {
    pathname: string
    currentPath: string
    ActiveIcon: any
    InactiveIcon: any
    label: string
}

export const Option: FC<IOptionProps> = (
    {
        pathname,
        currentPath,
        label,
        ActiveIcon,
        InactiveIcon
    }
) => {
    const history = useHistory()
    return (
        <Container
            orientation="horizontal"
            align="left"
            className={`
            ${styles["sidemenu-option"]}
            ${pathname === currentPath ?
                    styles["sidemenu-option-active"]
                    : undefined}
            `}
            onClick={() => history.push(currentPath)}
        >
            <>
                {pathname === currentPath ? (
                    ActiveIcon
                ) : (
                    InactiveIcon
                )}
                <Link to={currentPath}>
                    {label}
                </Link>
            </>
        </Container>
    )
}