import React, { useState, useContext } from "react";
import CustomButton from "../CustomButton/CustomButton";
import Overlay from "../Overlay/Overlay";
import WheelContext from "../../Context/WheelContext";
import "./SearchCustomWheel.css";
import { useHistory } from "react-router";

const SearchCustomWheel = () => {
  const W_CONTEXT = useContext(WheelContext);

  const history = useHistory();

  const [showBoltPatternOverlay, setShowBoltPatternOverlay] = useState(false);
  const [boltPattern, setBoltPattern] = useState("");
  const [width, setWidth] = useState("");
  const [diameter, setDiameter] = useState("");
  const [results, setResults] = useState([]);

  const styles = {
    border: "1px solid #fff",
    marginTop: 20,
  };

  return (
    <div
      style={{ width: "90%", margin: "0 auto" }}
      className="search__custom__wheel__container"
    >
      <CustomButton
        styles={styles}
        color={"transparent"}
        onClickDo={() => setShowBoltPatternOverlay(true)}
        height={window.innerWidth > 900 ? 55 : 80}
        width={"100%"}
        borderRadius={10}
      >
        {boltPattern === "" ? "Bolt Pattern" : boltPattern}
      </CustomButton>
      {showBoltPatternOverlay && (
        <Overlay
          list={W_CONTEXT!.boltPattern}
          show={showBoltPatternOverlay}
          onCloseBtn={() => setShowBoltPatternOverlay(false)}
          pickOption={(year: string) => {
            setBoltPattern(year);
            setShowBoltPatternOverlay(false);
          }}
        />
      )}
      <div className="add__custom__wheel__size__container">
        <input
          maxLength={2}
          placeholder="Diameter"
          className="add__custom__wheel__size__input"
          onChange={(ev) => setDiameter(ev.target.value)}
        />
        <p style={{ color: "#fff", fontSize: 20 }}>X</p>
        <input
          maxLength={3}
          placeholder="Width"
          className="add__custom__wheel__size__input"
          onChange={(ev) => setWidth(ev.target.value)}
        />
      </div>
      <CustomButton
        styles={styles}
        color={"transparent"}
        onClickDo={() =>
          history.push(
            `/results/customWheels?diameter=${diameter}&width=${width}&boltPattern=${boltPattern}`
          )
        }
        height={window.innerWidth > 900 ? 55 : 80}
        width={"100%"}
        borderRadius={10}
      >
        Search
      </CustomButton>
    </div>
  );
};

export default SearchCustomWheel;
