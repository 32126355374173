import React, { FC, SetStateAction } from "react";
import "./Overlay.css";

import CustomButton from "../CustomButton/CustomButton";
import { CgCloseR } from "react-icons/cg";

interface OverlayProps {
  list: string[]
  pickOption: (make: string) => void
  show: boolean
  onCloseBtn: () => void
}

const Overlay: FC<OverlayProps> = (props) => {
  const populateOverlay = () => {
    return props.list.map((make, idx) => (
      <CustomButton
        key={idx}
        styles={{
          marginTop: 5,
          marginBottom: 5,
          fontSize: "revert",
          marginRight: 2,
          marginLefft: 2,
        }}
        height={60}
        width={"30%"}
        color={"#202020"}
        borderRadius={10}
        onClickDo={() => props.pickOption(make)}
      >
        {make}
      </CustomButton>
    ));
  };
  return (
    <div className="overlay" style={{ display: props.show ? "block" : "none" }}>
      <CgCloseR
        className="overlay__close__btn"
        size={40}
        onClick={props.onCloseBtn}
        color={"rgb(183 69 69)"}
      />
      <div className="overlay__button__container">{populateOverlay()}</div>
    </div>
  );
};

export default Overlay;
