import styles from "./Section.module.scss"
import { FC, ReactNode } from "react"

interface ISectionProps {
    children: ReactNode
    className?: string
}

export const Section: FC<ISectionProps> = ({ children, className }) => {
    return (
        <section className={`${styles.section} ${className}`} >
            {children}
        </section>
    )
}