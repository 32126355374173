import { CreateOrganizationRequest } from "../data-transfer-objects/request/create-organization.dtc";
import { CreateOrganizationResponse } from "../data-transfer-objects/response/create-organization-response.dtc";
import { config } from "../config";

export class OrganizationService {
  createOrganization = async (
    createOrganizationRequest: CreateOrganizationRequest
  ): Promise<CreateOrganizationResponse | string> => {
    const response = await fetch(`${config.API_ENDPOINT}/companies`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(createOrganizationRequest),
    });

    const parsedResponse = await response.json();

    if (!response.ok) {
      throw new Error(parsedResponse as string);
    }

    return parsedResponse;
  }
  getOrganizationDetails = async (id: string) => {
    const response = await fetch(`${config.API_ENDPOINT}/companies/getinfo?companyId=${id}`
    )
    const jsonResponse = await response.json()
    return jsonResponse
  }
}
