import React, { useState, FC } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import "./CheckoutFormPage.css";
import {config} from "../../config";
import CustomButton from "../../components/CustomButton/CustomButton";
import { FaRegCalendarCheck } from "react-icons/fa";
import { useHistory } from "react-router";
import ReactLoading from "react-loading";
import AuthApiService from "../../Services/auth-api-service";


const CheckoutFormPage: FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const CARD__OPTIONS = {
    style: {
      base: {
        color: "#fff",
      },
    },
  };

  const handleSubmitSub = async (ev: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    ev.preventDefault();
    setErr(false);
    setErrMsg("");

    if (
      companyName.length < 1 ||
      userEmail.length < 1 ||
      nameOnCard.length < 1
    ) {
      setErr(true);
      setErrMsg("Please complete missing fields");
      setIsLoading(false);
      return;
    }

    let emailExist = await AuthApiService.verifyEmail(userEmail);

    if (!emailExist) {
      setIsLoading(false);
      setErrMsg("Email not fount, please verify and try again");
      setErr(true);
      return;
    }

    console.log(elements)

    if (!stripe || !elements) {
      console.log("hi")
      setIsLoading(false)
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement!,
      billing_details: {
        email: userEmail,
        name: nameOnCard,
      },
    });

    if (result.error) {
      setErrMsg("Something went wrong please try again");
      setErr(true);
      setIsLoading(false)
      return;
    } else {
      const response = await fetch(`${config.API_ENDPOINT}/payments/sub`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: userEmail,
          payment_method: result.paymentMethod.id,
        }),
      })
        .then((res) => res.json())
        .then((jsonResponse) => {
          const { status } = jsonResponse;

          if (status) {
            setIsLoading(false);
            setNameOnCard("");
            setUserEmail("");
            setCompanyName("");
            history.push("/paymentsuccess");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setErrMsg("something went wrong, try again");
          setErr(true);
          setIsLoading(false);
        });
    }
    setIsLoading(false);
  };

  return (
    <div className="checkout__page__container">
      <div className="welcome__text__container">
        <h1 className="checkout__title">Welcome to 360!</h1>
        <p className="checkout__text">
          We want to welcome you to team 360. It is a long way ahead, and we
          hope to be able to help you along the way. As a courtesy we will
          provide a trial period of 6 months where you can explore the product
          and make the most of it. To move ahead we just need your payment
          information. Your card will not be charged, until the trial period is
          over.
        </p>
      </div>
      <div className="payment__container">
        <div className="subscription__container">
          <FaRegCalendarCheck size={40} color={"white"} />
          <div className="subscription__text">
            <p style={{ fontSize: 19 }}>Base Membership</p>
          </div>
        </div>
        <h2 className="title__checkout">Set up your payment</h2>
        <form onSubmit={handleSubmitSub} className="checkout__form__container">
          <input
            onChange={(ev) => setCompanyName(ev.target.value)}
            className="checkout__input"
            placeholder="Company name"
          />
          <input
            onChange={(ev) => setUserEmail(ev.target.value)}
            className="checkout__input"
            placeholder="Email associated with account"
          />
          <input
            onChange={(ev) => setNameOnCard(ev.target.value)}
            className="checkout__input"
            placeholder="Name on card"
          />
          <CardElement
            options={CARD__OPTIONS}
            className="checkout__card__element"
          />
          {isLoading ? (
            <ReactLoading height={50} width={50} />
          ) : (
            <CustomButton
              height={50}
              width={"100%"}
              borderRadius={10}
              color={"#ffec00"}
              fontColor={"#121212"}
              styles={{ marginTop: 25 }}
              type="submit"
              onClickDo={() => { }}
            >
              Submit Payment
            </CustomButton>
          )}
        </form>
        <div className="checkout__error__container">
          {err && <p>{errMsg}</p>}
        </div>
      </div>
    </div>
  );
};

export default CheckoutFormPage;
