export const config = {
  // API_ENDPOINT: 'http://localhost:8000/api',
  API_ENDPOINT: "https://limitless-fjord-99628.herokuapp.com/api",
  API_KEY: process.env.REACT_APP_API_KEY,
  TOKEN_KEY: "redline-auth-token",
  USER_NAME: "rollos_oracle",
  USER_ID: "mistick",
  SUBSCRIPTION_ID: "caramelos",
  USER_EMAIL: "ldh123kj",
  ST_KEY:
    "pk_live_51JGUBpAO4xRVDSy6rJGTcukB4KlfB5fTSFfyXHUhpHfW6Z7Qs3aNNOgxH34S20sVYcPNeTB5Z6JSp3rk8KYUC12U00eabfVksM",
  SUB_STATE: "updateVersion",
  ORGANIZATION_KEY: "organization",
  ORGANIZATION_NAME: "identification",
  DEFAULT_TIRE_IMAGE:
    "https://res.cloudinary.com/redlineinventory/image/upload/v1654973022/no-tire-image_jikv1j.png",
};
