import React from "react"
import "./OnBoard.scss"

const OnBoard: React.FC = () => {
    return (
        <div className="on__board__container">
            <div className="on__board__header__container">
                <h1 className="on__board__header">Documentation</h1>
                <h2 className="on__board__sub__header">Quick start</h2>
                <p className="on__board__text">
                    After you have signed up and your application has been approved
                    the best way to get started with 360 is to create a shortcut to the app.
                    We will walk you, and show you how to do it on your desktop and your phone.
                </p>
                <h3 className="on__board__tast__title">Create a shortcut on desktop:</h3>
                <ul className="on__board__list">
                    <li className="list__item" >
                        Open Google Chrome, and navigate to the login page or click this link <a className="on__board__login__link" rel="noopener noreferrer" target="_blank"
                            href="https://app.360automotiveparts.com/" >Login Page</a>

                        <div className="on__board__image__container">
                            <img width={"100%"} alt="login-page-sample" src="https://res.cloudinary.com/redlineinventory/image/upload/v1639975245/loginPage_r8l8cw.png" />
                        </div>
                        it should look like the sample above
                    </li>
                    <li className="list__item">
                        Once, on this page go to the three dots on the right upper corner of your screen, and click
                        <div className="on__board__image__container">
                            <img width={"100%"} alt="login-page-sample" src="https://res.cloudinary.com/redlineinventory/image/upload/v1639976232/threeDots_l4q9n7.png" />
                        </div>
                    </li>
                    <li className="list__item">
                        On the menu go to to "More tools", and from the submenu click on "Create shortcut"
                        <div className="on__board__image__container">
                            <img width={"100%"} alt="login-page-sample" src="https://res.cloudinary.com/redlineinventory/image/upload/v1639976810/menu_rle7gc.png" />
                        </div>
                    </li>
                    <li className="list__item">
                        On the popup menu that was open make sure to select "open as window", and then click "Create"
                        <div className="on__board__image__container">
                            <img width={"100%"} alt="login-page-sample" src="https://res.cloudinary.com/redlineinventory/image/upload/v1639977262/shortcutSelect_i7xxfg.png" />
                        </div>
                    </li>
                    <li className="list__item">
                        if you did everything correctly a new window would open with 360 app on it, and if you go to your desktop there should be an icon like the one on the sample below
                        <div className="on__board__image__container">
                            <img width={"20%"} alt="login-page-sample" src="https://res.cloudinary.com/redlineinventory/image/upload/v1639977582/360_keurye.png" />
                        </div>
                        now everytime you want to start a session you can just double click on the desktop icon
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default OnBoard;