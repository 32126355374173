import { FC } from "react";
import styles from "./SideMenu.module.scss";
import { useLocation } from "react-router-dom";
import { IoHomeOutline, IoHome } from "react-icons/io5";
import { BsPlusSquareFill, BsPlusSquare } from "react-icons/bs";
import { RiSearchEyeLine, RiSearchEyeFill } from "react-icons/ri";
import { AiOutlineSetting, AiFillSetting } from "react-icons/ai";
import { IoChatboxEllipses, IoChatboxEllipsesOutline } from "react-icons/io5";
import { BiMoney } from "react-icons/bi"
import { FaMoneyBillAlt } from "react-icons/fa"
import { Container } from "../../common/Container";
import { Logo } from "../../common/Logo"
import { Option } from "./Option"

export const SideMenu: FC = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Container className={styles.sidemenu} orientation="vertical">
      <Logo size="large" />
      <Option
        pathname={pathname}
        currentPath="/inventory"
        label="My store"
        ActiveIcon={<IoHome size={20} color="white" />}
        InactiveIcon={<IoHomeOutline size={20} color="white" />}
      />
      <Option
        pathname={pathname}
        currentPath="/chat"
        label="Chat"
        ActiveIcon={<IoChatboxEllipses size={25} color="white" />}
        InactiveIcon={<IoChatboxEllipsesOutline size={25} color="white" />}
      />
      <Option
        pathname={pathname}
        currentPath="/addItem"
        label="Add Item"
        ActiveIcon={<BsPlusSquareFill size={25} color="white" />}
        InactiveIcon={<BsPlusSquare size={25} color="white" />}
      />
      <Option
        pathname={pathname}
        currentPath="/search"
        label="Search"
        ActiveIcon={<RiSearchEyeFill size={25} color="white" />}
        InactiveIcon={<RiSearchEyeLine size={25} color="white" />}
      />
        <Option
         pathname={pathname}
         currentPath="/financing"
         label="Financing"
         ActiveIcon={<FaMoneyBillAlt size={25} color="white" />}
         InactiveIcon={<BiMoney size={25} color="white" />}
       />
      <Option
        pathname={pathname}
        currentPath="/settings"
        label="Settings"
        ActiveIcon={<AiFillSetting size={25} color="white" />}
        InactiveIcon={<AiOutlineSetting size={25} color="white" />}
      />
     
    </Container>
  );
};

