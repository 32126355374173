import { useState, FC } from "react";
import SearchCustomWheel from "./SearchCustomWheel";
import SearchOemWheel from "./SearchOemWheel";

const SearchWheelComponent: FC = () => {
  const [wheelType, setWheelType] = useState("");

  return (
    <>
      <label style={{ color: "#fff", fontSize: 20 }}>
        Please Select a category:
      </label>
      <div className="add__wheel__checkbox__container">
        <div className="add__wheel__radio__container">
          <label style={{ color: "#fff", fontSize: 20 }}>OEM</label>
          <input
            onClick={() => setWheelType("OEM")}
            name="wheelType"
            type="radio"
          />
        </div>
        <div className="add__wheel__radio__container">
          <label style={{ color: "#fff", fontSize: 20 }}>Custom</label>
          <input
            onClick={() => setWheelType("Custom")}
            name="wheelType"
            type="radio"
          />
        </div>
      </div>
      {wheelType === "OEM" && <SearchOemWheel />}
      {wheelType === "Custom" && <SearchCustomWheel />}
    </>
  );
};

export default SearchWheelComponent;
