import { makeAutoObservable } from "mobx";
import { TireService } from "../Services/tire.service";
import { TireModel } from "../Model/tire";

export class TireStore {
  public myTires: any[] = [];
  public myTiresBySize: any[] = [];
  public myOwnTireSize: string = "";
  public tireError: string = "";
  constructor(private readonly tireService: TireService) {
    makeAutoObservable(this);
  }

  getMyOwnTires = async () => {
    try {
      const response = await this.tireService.getMyOwnTires();
      this.setMyOwnTires(response);
    } catch (error) {
      console.log(error);
    }
  };

  getMyOwnTiresBySize = async (tireSize?: string) => {
    try {
      if (this.myOwnTireSize.length < 6 && !!!tireSize) {
        this.setTireError("Please enter a valid size");
        return;
      }
      const response = await this.tireService.getMyOwnTiresBySize(
        tireSize ? tireSize : this.myOwnTireSize,
        "used"
      );
      this.setMyTiresBySize(response);
    } catch (error) {
      console.log("error");
    }
  };

  queryTiresBySize =async (tireSize: string) => {
    try{
      const response = await this.tireService.queryTiresBySize(tireSize)
      this.setMyTiresBySize(response)
    }catch(e){
      throw e
    }
  }


  getTireById = async (id: string) => {
    try {
      const response = await this.tireService.getTireById(id);
      if (response.length === 0) return "No tires found";
      return response;
    } catch (error) {
      console.log("Tire", error);
    }
  };


  postTire = async (tire: TireModel) => {
    try {
      const response = await this.tireService.postNewTire(tire);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  //Setters
  setMyTiresBySize = (_: any[]) => {
    this.myTiresBySize = _;
  };
  setMyOwnTires = (_: any[]) => {
    this.myTires = _;
  };
  setMyOwnTireSize = (_: string) => {
    this.myOwnTireSize = _;
  };
  setTireError = (_: string) => {
    this.tireError = _;
  };
}
