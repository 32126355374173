import { LoginRequest } from "../data-transfer-objects/request/login-request.dtc";
import { LoginResponse } from "../data-transfer-objects/response/login-response.dtc";
import { config } from "../config";

export class AuthService {
  login = async (
    loginRequest: LoginRequest
  ): Promise<LoginResponse | string> => {
    const { email, password } = loginRequest;
    const response = await fetch(`${config.API_ENDPOINT}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    const parsedResponse = await response.json();

    if (!!parsedResponse.error) {
      return parsedResponse.error;
    }

    if (!response.ok) {
      throw new Error(parsedResponse as string);
    }

    return parsedResponse;
  };
}
