import React, { useState, useEffect } from "react";
import "./AddWheelComponent.css";
import { useParams } from "react-router";

import AddCustomWheel from "./AddCustomWheel";
import AddOemWheel from "./AddOemWheel";

type partTypeParam = {
  partType: string
}

const AddWheelComponent = () => {
  const [wheelType, setWheelType] = useState("");

  const { partType } = useParams<partTypeParam>();

  useEffect(() => {
    if (partType === "customwheels") {
      setWheelType("Custom");
    }
    if (partType === "oemWheels") {
      setWheelType("OEM");
    }
  }, []);

  return (
    <>
      <label style={{ color: "#fff", fontSize: 20 }}>
        Please Select a category:
      </label>
      <div className="add__wheel__checkbox__container">
        <div className="add__wheel__radio__container">
          <label style={{ color: "#fff", fontSize: 20 }}>OEM</label>
          <input
            onClick={() => setWheelType("OEM")}
            name="wheelType"
            type="radio"
          />
        </div>
        <div className="add__wheel__radio__container">
          <label style={{ color: "#fff", fontSize: 20 }}>Custom</label>
          <input
            onClick={() => setWheelType("Custom")}
            name="wheelType"
            type="radio"
          />
        </div>
      </div>
      {wheelType === "OEM" && <AddOemWheel />}
      {wheelType === "Custom" && <AddCustomWheel />}
    </>
  );
};

export default AddWheelComponent;
