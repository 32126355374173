import { makeAutoObservable } from "mobx";
import { CreateOrganizationRequest } from "../data-transfer-objects/request/create-organization.dtc";
import { OrganizationService } from "../Services/organization.service";
import { CreateOrganizationResponse } from "../data-transfer-objects/response/create-organization-response.dtc";

export class OrganizationStore {
  public organizationName: string = "";
  public organizationAddress: string = "";
  public organizationCity: string = "";
  public organizationState: string = "";
  public organizationZipcode: string = "";
  public organizationPhone: string = "";
  public organizationTaxNumber: string = "";
  public organizationCreated: boolean;
  public error: string = "";
  public isLoading: boolean = false;

  constructor(private readonly organizationService: OrganizationService) {
    makeAutoObservable(this);
    this.organizationCreated = false;
    this.organizationName = "";
  }

  createOrganization = async () => {
    this.setIsLoading(true);
    const _: CreateOrganizationRequest = {
      company_name: this.organizationName!,
      company_address: this.organizationAddress!,
      company_city: this.organizationCity!,
      company_state: this.organizationState!,
      company_zipcode: this.organizationZipcode!,
      company_phonenumber: this.organizationPhone!,
      company_tax_id: this.organizationTaxNumber!,
    };

    Object.keys(_).forEach((key: string) => {
      if ((_ as any)[key] === undefined || (_ as any)[key].length <= 0) {
        this.setError("Complete missing fields");
        return;
      }
    });

    if (!!this.error) {
      this.setIsLoading(false);
      return;
    }

    try {
      const response: CreateOrganizationResponse | string =
        await this.organizationService.createOrganization(_);
      if (typeof response === "string") {
        console.log("error");
        this.setError(response);
        this.setIsLoading(false);
        return;
      }
      localStorage.setItem("organization", response.company_name);
      this.setOrganizationCreated(true);
      this.setIsLoading(false);
    } catch (error) {
      this.setError(error as string);
      this.setOrganizationCreated(false);
      this.setIsLoading(false);
    }
  };

  getOrganizationsDetails = async (id: string) => {
    try{
      const response = await this.organizationService.getOrganizationDetails(id)
      this.setOrganizationAddress(response.company_address)
      this.setOrganizationCity(response.company_city)
      this.setOrganizationName(response.company_name)
      this.setOrganizationPhoneNumber(response.company_phonenumber)
      this.setOrganizationState(response.company_state)
      this.setOrganizationZipcode(response.company_zipcode)
    }catch(e){
      throw e;
    }
  }

  //setters
  setOrganizationCreated = (_: boolean) => {
    this.organizationCreated = _;
  };
  setError = (_: string) => {
    this.error = _;
  };
  setOrganizationName = (_: string) => {
    this.setError("");
    this.organizationName = _;
  };
  setOrganizationAddress = (_: string) => {
    this.setError("");
    this.organizationAddress = _;
  };
  setOrganizationCity = (_: string) => {
    this.setError("");
    this.organizationCity = _;
  };
  setOrganizationState = (_: string) => {
    this.setError("");
    this.organizationState = _;
  };
  setOrganizationZipcode = (_: string) => {
    this.setError("");
    this.organizationZipcode = _;
  };
  setOrganizationPhoneNumber = (_: string) => {
    this.setError("");
    this.organizationPhone = _;
  };
  setOrganizationTaxNumber = (_: string) => {
    this.setError("");
    this.organizationTaxNumber = _;
  };
  setIsLoading = (_: boolean) => {
    this.isLoading = _;
  };
}
