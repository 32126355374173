import React, { FC } from "react"
import styles from "./Input.module.scss"


interface IInputProps {
    placeholder?: string
    type?: string
    maxLength?: number
    onChange: React.Dispatch<React.SetStateAction<string>> | ((_: string) => void)
}

export const Input: FC<IInputProps> = ({ placeholder, type, maxLength, onChange }) => {
    return <input
        maxLength={maxLength}
        autoComplete="one-time-code" autoCapitalize="off"
        onChange={(ev) => onChange(ev.target.value)}
        type={type}
        placeholder={placeholder}
        className={styles.input}
    />

}