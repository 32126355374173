import React from "react";
import { CompanyType } from "../types/CompanyType";

//Context needs to ve updated with types and separation of concerns

interface UserContextType {
  isUserAuthenticated: boolean,
  companyName: string | null,
  companyId: number | null,
  user: string | null,
  userEmail: string | null,
  companyInfo: CompanyType | null,
  isSubActive: boolean,
  setCompanyId: (a: number) => void
  setUser: (a: string) => void
  setAuth: (a: boolean) => void
  setCompanyName: (a: string) => void,
  setCompanyInfo: (arg0: CompanyType | null) => void,
  setUserEmail: (a: string) => void,
  setIsSubActive: (a: boolean) => void,
}

const defaulState = {
  isUserAuthenticated: false,
  companyName: null,
  companyId: null,
  user: null,
  userEmail: null,
  companyInfo: null,
  isSubActive: false,
  setCompanyId: () => { },
  setUser: () => { },
  setAuth: () => { },
  setCompanyName: () => { },
  setCompanyInfo: () => { },
  setUserEmail: () => { },
  setIsSubActive: () => { }
}
const UserContext = React.createContext<UserContextType>(defaulState);

export default UserContext