import React, { useState, FC } from "react";
import "./TireSearchComponent.css";
import CustomButtom from "../CustomButton/CustomButton";
import { useHistory } from "react-router";

//New Imports MUI

import { TextField, Button } from "@mui/material";

const TireSeachComponent: FC = () => {
  const styles = {
    border: "1px solid #fff",
    fontSize: "25px",
    marginTop: "10px",
  };

  const history = useHistory();
  const [size, setSize] = useState("");

  return (
    <div className="tire__search__container">
      <div className="tire__search__input__container">

        <form onSubmit={(ev) => {
          ev.preventDefault()
          history.push(`/results/tires?myStore=false&tireSize=${size}`)
        }} >
          <TextField
            variant="outlined"
            onChange={(ev) => setSize(ev.target.value)}
            label="Tire Size"
            sx={{
              width: "100%",
              marginBottom: 2
            }}
          />
          <Button
            variant="contained"
            size="large"
            type="submit"
            sx={{
              width: "100%"
            }}
          >Search
          </Button>
        </form>
      </div>
    </div>
  );
};

export default TireSeachComponent;
