import { makeAutoObservable } from "mobx";
import { LoginRequest } from "../data-transfer-objects/request/login-request.dtc";
import { LoginResponse } from "../data-transfer-objects/response/login-response.dtc";
import { AuthService } from "../Services/auth.service";
import { TokenService } from "../Services/token.service";

export class AuthStore {
  public authenticated: boolean = false;
  private loginEmail: string = "";
  private loginPassword: string = "";
  public error: string = "";
  public isLoading: boolean = false;
  public subscriptionStatus: "active" | "newMember" | "passdue" | undefined =
    undefined;

  constructor(private readonly authService: AuthService) {
    makeAutoObservable(this);
    this.authenticated = !!this.getAccessToken();
    this.error = "";
    this.subscriptionStatus = undefined;
  }

  login = async () => {
    if(this.loginEmail.length < 5 || this.loginPassword.length < 1){
      this.setError("Enter a valid email or password")
      this.setIsLoading(false)
      return
    }
    try {
      const response: LoginResponse | string = await this.authService.login({
        email: this.loginEmail,
        password: this.loginPassword,
      });
      if (typeof response === "string") {
        this.setError(response);
        this.setIsLoading(false);
        return;
      }
      if (response.subid === "newMember") {
        this.setSubscriptionStatus("newMember");
        return;
      }
      this.setLoginEmail("")
      this.setLoginPassword("")
      TokenService.saveAuthToken(response.authToken);
      TokenService.saveCompanyId(response.companyId.toString());
      TokenService.saveCompanyName(response.company_name);
      TokenService.saveSubscriptionId(response.subid);
      TokenService.saveUserEmail(response.user);
      this.setAuthenticated(true);
      this.setSubscriptionStatus("active");
      this.setIsLoading(false);
    } catch (err) {
      this.setAuthenticated(false);
    }
  };

  logOut = () => {
    TokenService.clearAuthToken();
    TokenService.saveCompanyId("");
    TokenService.saveCompanyName("");
    TokenService.saveSubscriptionId("");
    TokenService.saveUserEmail("");
    this.setAuthenticated(false);
  };

  // Setters

  setLoginEmail = (_: string) => {
    this.loginEmail = _;
  };

  setLoginPassword = (_: string) => {
    this.loginPassword = _;
  };

  setSubscriptionStatus(
    _: "active" | "newMember" | "passdue" | undefined = undefined
  ) {
    this.subscriptionStatus = _;
  }

  setIsLoading = (_: boolean) => {
    this.isLoading = _;
  }

  setError(_: string) {
    this.error = _;
  }

  setAuthenticated(authenticated: boolean) {
    if (!authenticated) TokenService.clearAuthToken();
    this.authenticated = authenticated;
  }

  getAccessToken() {
    return TokenService.getAuthToken();
  }

  isAuthenticated() {
    return this.authenticated;
  }
}
