import { makeAutoObservable } from "mobx";
import { CustomWheelService } from "../Services/customwheel.service";

export class CustomWheelStore {
  public myCustomWheels: any[] = [];

  constructor(private readonly customWheelService: CustomWheelService) {
    makeAutoObservable(this);
  }
  getMyCustomWheels = async () => {
    try {
      const response = await this.customWheelService.getMyCustomWheels();
      this.setMyCustomWheels(response);
    } catch (error) {
      console.log(error);
    }
  };
  setMyCustomWheels = (_: any[]) => {
    this.myCustomWheels = _;
  };
}
