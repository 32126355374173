import { FC, ReactNode } from "react"
import styles from "./Button.module.scss"

interface IButtonProps {
    children: ReactNode
    type: "primary" | "secondary" | "tertiary"
    size: "large" | "medium" | "small"
    submitButton?: boolean
    onClick?: () => void
}

export const Button: FC<IButtonProps> = ({ children, type, size, submitButton, onClick }) => {
    return <button onClick={onClick} type={submitButton ? "submit" : "button"} className={`${styles.button} ${styles[`button-${type}`]} ${styles[`button-${size}`]} `} >{children}</button>
}