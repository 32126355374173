import { FC, useContext } from "react"
import { Typography } from "../../common/Typography"
import { StoreContext } from "../../store.context"
import { Container } from "../../common/Container"
import styles from "./Header.module.scss"
interface IHeaderProps {
    cartPart: "tires" | "oemwheels" | "customwheels"
}

export const Header: FC<IHeaderProps> = ({ cartPart }) => {
    const { tireStore, oemWheelStore, customWheelStore } = useContext(StoreContext)
    return (
        <Container justifyContent="space-between"
        className={styles["inventory-header"]}
        align="left" orientation="vertical" >
            <Typography type="h1" >Inventory</Typography>
            <Typography type="h4">{`Quantity: 
           ${cartPart === "tires" ? tireStore.myTires.length :
                    cartPart === "oemwheels" ? oemWheelStore.myOEMWheels.length :
                        cartPart === "customwheels" ? customWheelStore.myCustomWheels.length : 0
                } `}
            </Typography>
        </Container>
    )
}