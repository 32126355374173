import { makeAutoObservable } from "mobx";
import { validatePassword } from "../Utils/validatePassword";
import { UserService } from "../Services/user.service";
import { verifyInput } from "../Utils/verifyInput";

export type resetPasswordState = "sendCode" | "enterCode" | "enterNewPassword";

export class UserStore {
  public userName: undefined | string;
  public userEmail: undefined | string;
  public userPassword: undefined | string;
  public confirmPassword: undefined | string;
  private userRole: string | undefined;
  public error: string = "";
  public isLoading: boolean = false;
  public resetPasswordState: resetPasswordState;
  public resetCode: string | undefined;

  constructor(private readonly userService: UserService) {
    makeAutoObservable(this);
    this.userRole = "admin";
    this.resetPasswordState = "sendCode";
  }

  resetPassword = async (): Promise<boolean> => {
    this.setIsLoading(true);
    console.log("here")
    const isPasswordValid = this.validatePassword();
    if (!isPasswordValid) return false;

    if (!!!this.userEmail) {
      this.setError("No user found");
      this.setIsLoading(false);
      return false;
    }
    try {
      const response = await this.userService.resetPassword(
        this.userEmail,
        this.userPassword!
      );
      if (typeof response === "string") {
        this.setError(response);
        this.setIsLoading(false);
        return false ;
      }
      
      this.setIsLoading(false);
      return true
    } catch (error) {
      this.setError(error as string);
      this.setIsLoading(false);
      return false
    }
  };

  createUser = async (): Promise<boolean | undefined> => {
    this.setIsLoading(true);
    const error = verifyInput([
      this.confirmPassword,
      this.userEmail,
      this.userName,
      this.userPassword,
    ]);

    if (!!error) {
      this.setError(error);
      this.setIsLoading(false);
      return;
    }

    const isPasswordValid = this.validatePassword();
    if (!isPasswordValid) return;

    const _ = {
      full_name: this.userName!,
      email: this.userEmail!,
      password: this.userPassword!,
      team_role: this.userRole!,
      company_name: localStorage.getItem("organization")!,
    };

    try {
      const response = await this.userService.createUser(_);
      if (typeof response === "string") {
        this.setError(response);
        this.setIsLoading(false);
        return;
      }
      return true;
    } catch (error) {
      this.setError(error as string);
      this.setIsLoading(false);
    }
  };

  sendResetCode = async () => {
    this.setIsLoading(true);
    if (this.userEmail === undefined || this.userEmail?.length < 5) {
      this.setError("Please enter a valid email");
      this.setIsLoading(false);
      return;
    }

    const response = await this.userService.sendResetCode(this.userEmail);
    if (typeof response === "string") {
      this.setError(response);
      this.setIsLoading(false);
      return;
    }
    if (response.ok) {
      this.setResetPasswordState("enterCode");
      this.setIsLoading(false);
      return;
    }
    this.setIsLoading(false);
    this.setError("Something went wrong!");
  };

  verifyCode = async () => {
    this.setIsLoading(true);
    this.setError("");
    if (!this.userEmail || !this.resetCode) {
      this.setError("Try resend another code");
      this.setIsLoading(false);
      return;
    }
    try {
      const response = await this.userService.verifyCode(
        this.userEmail,
        this.resetCode
      );
      if (typeof response === "string") {
        this.setError(response);
        this.setIsLoading(false);
        return;
      }
      this.setIsLoading(false)
      this.setResetPasswordState("enterNewPassword");
    } catch (error) {
      this.setError(error as string);
    }
  };

  validatePassword = (): boolean => {
    if (this.userPassword !== this.confirmPassword) {
      this.setError("Password don't match");
      this.setIsLoading(false);
      return false;
    }

    const passwordError = validatePassword(this.userPassword);
    if (!!passwordError) {
      this.setError(passwordError);
      this.setIsLoading(false);
      return false;
    }
    return true;
  };


  //Setters
  setError = (_: string) => {
    this.error = _;
  };
  setUserName = (_: string) => {
    this.setError("");
    this.userName = _;
  };
  setUserEmail = (_: string) => {
    this.setError("");
    this.userEmail = _;
  };
  setPassword = (_: string) => {
    this.setError("");
    this.userPassword = _;
  };
  setConfirmPassword = (_: string) => {
    this.setError("");
    this.confirmPassword = _;
  };
  setIsLoading = (_: boolean) => {
    this.isLoading = _;
  };
  setResetPasswordState = (_: resetPasswordState) => {
    this.resetPasswordState = _;
  };
  setResetCode = (_: string) => {
    this.resetCode = _;
  };
}
