import React from "react";
import { AuthStore } from "./Stores/auth.store";
import { AuthService } from "./Services/auth.service";
import { OrganizationStore } from "./Stores/organization.store";
import { OrganizationService } from "./Services/organization.service";
import { UserStore } from "./Stores/user.store";
import { UserService } from "./Services/user.service";
import { TireStore } from "./Stores/tires.store";
import { TireService } from "./Services/tire.service";
import { CustomWheelService } from "./Services/customwheel.service";
import { CustomWheelStore } from "./Stores/customwheel.store";
import { OEMWheelService } from "./Services/oemwheels.service";
import { OEMWheelStore } from "./Stores/oemwheel.store";

interface IStoreContext {
  authStore: AuthStore;
  organizationStore: OrganizationStore;
  userStore: UserStore;
  tireStore: TireStore;
  oemWheelStore: OEMWheelStore;
  customWheelStore: CustomWheelStore;
}

const oemWheelService = new OEMWheelService();
const oemWheelStore = new OEMWheelStore(oemWheelService);

const customWheelService = new CustomWheelService();
const customWheelStore = new CustomWheelStore(customWheelService);

const tireService = new TireService();
const tireStore = new TireStore(tireService);

const userService = new UserService();
const userStore = new UserStore(userService);

const organizationService = new OrganizationService();
const organizationStore = new OrganizationStore(organizationService);

const authService = new AuthService();
const authStore = new AuthStore(authService);

export const StoreContext = React.createContext<IStoreContext>({
  authStore,
  organizationStore,
  userStore,
  tireStore,
  oemWheelStore,
  customWheelStore,
});
