import React, { FC } from "react";
import styles from "./Menu.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { IoHomeOutline, IoHome } from "react-icons/io5";
import { BsPlusSquareFill, BsPlusSquare } from "react-icons/bs";
import { RiSearchEyeLine, RiSearchEyeFill } from "react-icons/ri";
import { AiOutlineSetting, AiFillSetting } from "react-icons/ai";
import { IoChatboxEllipses, IoChatboxEllipsesOutline } from "react-icons/io5";

//NEW import 
import { Option } from "./Option"
import { Container } from "../../common/Container";
export const Menu: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const { pathname } = location;

  return (
    <Container className={styles.menu} orientation="horizontal">
        <Option
          pathname={pathname}
          currentPath="/inventory"
          ActiveIcon={<IoHome size={35} color="white" />}
          InactiveIcon={<IoHomeOutline size={35} color="white" />}
        />
        <Option
          pathname={pathname}
          currentPath="/addItem"
          ActiveIcon={<BsPlusSquareFill size={35} color="white" />}
          InactiveIcon={<BsPlusSquare size={35} color="white" />}
        />
        <Option
          pathname={pathname}
          currentPath="/search"
          ActiveIcon={<RiSearchEyeFill size={35} color="white" />}
          InactiveIcon={<RiSearchEyeLine size={35} color="white" />}
        />
          <Option
           pathname={pathname}
           currentPath="/chat"
           ActiveIcon={<IoChatboxEllipses size={35} color="white" />}
           InactiveIcon={<IoChatboxEllipsesOutline size={35} color="white" />}
            />
        <Option
          pathname={pathname}
          currentPath="/settings"
          ActiveIcon={<AiFillSetting size={35} color="white" />}
          InactiveIcon={<AiOutlineSetting size={35} color="white" />}
       
        />
    </Container>
  );
};

