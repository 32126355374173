import { Form } from "../../common/Form"
import { FormInput } from "../../components/FormInput/FormInput"
import { Section } from "../../common/Section"
import styles from "./LoginForm.module.scss"
import React, { FC } from "react"
import { Link } from "react-router-dom"
import { Container } from "../../common/Container"
import { observer } from "mobx-react-lite"
// import { Button } from "../../common/Button"
import { Loading } from "../../common/Loading"
import { Input } from "../../common/Input"

import { TextField, Button } from "@mui/material"
interface ILoginFormProps {
    onSubmit: () => void
    onChangeEmail: React.Dispatch<React.SetStateAction<string>> | ((_: string) => void)
    onChangePassword: React.Dispatch<React.SetStateAction<string>> | ((_: string) => void)
    isLoading: boolean
}

export const LoginForm: FC<ILoginFormProps> = observer(({
    onSubmit,
    onChangeEmail,
    onChangePassword,
    isLoading }) => {


    return (
        <Section className={styles["login-form"]}>
            <Form onSubmit={onSubmit}>
                <TextField 
                variant="outlined"
                label="Email"
                onChange={(ev) => onChangeEmail(ev.target.value)}
                sx={{
                    marginBottom: 3,
                    fontSize: 20,
                    "& label": {
                        color: "white"
                    }
                }}
                />
                 <TextField 
                variant="outlined"
                label="Password"
                type="password"
                onChange={(ev) => onChangePassword(ev.target.value)}
                sx={{
                    marginBottom: 3,
                    fontSize: 20
                }}
                />
               
                <Container orientation="vertical" align="right">
                    <Link
                        to="/resetPassword"
                        className={styles["form-link"]}
                    >
                        Forgot Password?
                    </Link>
                </Container>
                {
                    isLoading ?
                        <Loading /> :
                        <Button 
                        variant="contained"
                        size="large"
                        type="submit"
                        >
                            Login
                        </Button>
                }
            </Form>
        </Section>
    )
})