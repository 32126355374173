import { Button } from "../../common/Button"
import { FormInput } from "../../components/FormInput/FormInput"
import { Container } from "../../common/Container"
import { useHistory } from "react-router-dom"
import React, { FC } from "react"
import { TokenService } from "../../Services/token.service"
import styles from "./OEM.module.scss"
import { TextField } from "@mui/material"

interface ITireSearchProps {
    setTireSize: React.Dispatch<React.SetStateAction<string>> | ((_: string) => void)
    tireSize: string
}

export const TireSearch: FC<ITireSearchProps> = ({
    setTireSize,
    tireSize
}
) => {

    const history = useHistory()
    return (
        <Container className={styles.interchange} orientation="vertical">

            <TextField
                label="Tire size"
                variant="outlined"
                onChange={(ev) => setTireSize(ev.target.value)}
                onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                        history.push(
                            `/results/tires?tireSize=${tireSize}&condition=used&companyId=${TokenService.getCompanyId()}`
                        )
                    }
                }}
            />
            <Button
                type="primary"
                size="large"
                onClick={() =>
                    history.push(
                        `/results/tires?tireSize=${tireSize}&condition=used&companyId=${TokenService.getCompanyId()}`
                    )
                }
            >
                Search
            </Button>
        </Container >
    )
}