import { createTheme, Theme, ThemeOptions } from "@mui/material";

const baseTheme = createTheme({
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    h1: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "2rem",
      lineHeight: "2.5rem",
      letterSpacing: 0,
    },
    h2: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "1.5rem",
      lineHeight: "2.125rem",
      letterSpacing: "0.069rem",
      fontFeatureSettings: `"zero" on`,
    },
    h3: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "1.125rem",
      lineHeight: "1.688rem",
      letterSpacing: "0.069rem",
    },
    h4: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "0.813rem",
      lineHeight: "1.375rem",
      letterSpacing: "0.069rem",
      textTransform: "uppercase",
    },
    h5: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "0.813rem",
      lineHeight: "1.125rem",
      letterSpacing: "0.038rem",
      textTransform: "uppercase",
    },
    h6: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "0.625rem",
      lineHeight: "0.938rem",
      letterSpacing: "0.063rem",
    },
    body1: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "1rem",
      lineHeight: "1.688rem",
      letterSpacing: "0.063rem",
      fontFeatureSettings: `"zero" on`,
    },
    body2: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "1rem",
      lineHeight: "1.688rem",
      letterSpacing: "0.063rem",
      fontFeatureSettings: `"zero" on`,
      fontVariantNumeric: "tabular-nums",
    },
    button: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: "1.05rem",
      letterSpacing: "0.053.rem",
      textTransform: "uppercase",
    },
  },
});

function createComponents(theme: Theme) {
  const components: ThemeOptions = {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            background: "#121212",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              background: "#121212",
              width: "0.5rem",
              height: "0.5rem",
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              background: theme.palette.primary.light,
              borderRadius: "0.25rem",
            },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
              {
                backgroundColor: theme.palette.primary.light,
              },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: theme.palette.primary.light,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: theme.palette.secondary.main,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(3),
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            backgroundColor: " rgb(33 33 33)",
            borderRadius: 25,
            paddingLeft: 7,
            paddingRight: 7,
            color: "white",
            fontSize: 18,
            fontWeight: 600,
            "&.Mui-focused": {
              color: "white",
              fontSize: 16,
            },
          },
        },
      },
      // MuiFilledInput: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: theme.palette.primary.dark,
      //       borderTop: "1px solid transparent",
      //       ":before": {
      //         borderBottom: `1px dotted ${theme.palette.primary.light}`,
      //       },
      //       "&.Mui-focused": {
      //         background: theme.palette.common.black,
      //         border: `1px solid ${theme.palette.secondary.main}`,
      //         borderBottom: "none",
      //         borderTop: `1px solid ${theme.palette.secondary.main}`,
      //       },
      //       ":after": {
      //         borderBottom: `1px solid ${theme.palette.secondary.main}`,
      //       },
      //     },
      //   },
      // },
      // MuiFormControl: {
      //   styleOverrides: {
      //     root: {
      //       border: "1px solid white",
      //       borderRadius: 8,
      //       "&.Mui-focused": {
      //         border: "transparent",
      //       },
      //     },
      //   },
      // },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            border: "1px solid white",
            backgroundColor: " rgb(33 33 33)",
            outline: "white",
            color: "white",
            fontSize: 16,
            fontWeight: 600,
            "&.Mui-focused": {
              border: "transparent",
            },
            "&.Mui-error": {
              border: "transparent",
            },
          },
          // input: {
          //   "&:-webkit-autofill": {
          //     "-webkit-box-shadow": `0 0 0 100px ${theme.palette.primary.dark} inset`,
          //     "-webkit-text-fill-color": theme.palette.primary.light,
          //   },
          // },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: "rgba(0, 0, 0, 0.87)",
            color: "white",
            padding: baseTheme.spacing(1.2),
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            color: theme.palette.common.white,
            fontStyle: "normal",
            fontWeight: 500,
            ...baseTheme.typography.h3,
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: theme.palette.primary.light,
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            willChange: "background-color , color , border-color , box-shadow",
            transition:
              "background-color 200ms ease-in-out 0ms , color 200ms ease-in-out 0ms , border-color 200ms ease-in-out 0ms , box-shadow 200ms ease-in-out 0ms",
            boxShadow: `0 0 0 0rem ${theme.palette.primary.main}`,
            "&.Mui-disabled": {
              color: theme.palette.primary.main,
            },
          },
          sizeSmall: {
            height: "1.25rem",
            fontWeight: 500,
            fontSize: "0.688rem",
            lineHeight: "0.825rem",
            letterSpacing: "0.047rem",
          },
          sizeMedium: {
            height: "1.813rem",
            fontWeight: 500,
          },
          sizeLarge: {
            height: "5rem",
            fontWeight: 600,
            fontSize: "2rem",
            lineHeight: "2.5rem",
            letterSpacing: "1px",
          },
          containedPrimary: {
            background: theme.palette.primary.main,
            color: theme.palette.common.black,
            "@media(hover: hover)": {
              "&:hover": {
                boxShadow: `0 0 0 0.4rem ${theme.palette.primary.main}`,
                background: theme.palette.primary.main,
              },
            },
          },
          containedSecondary: {
            color: theme.palette.common.black,
            "@media(hover: hover)": {
              "&:hover": {
                boxShadow: `0 0 0 0.4rem ${theme.palette.secondary.main}`,
                background: theme.palette.secondary.main,
              },
            },
          },
          outlinedPrimary: {
            color: "white",
            border: "1px solid white",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            background: theme.palette.background.paper,
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.primary.dark,
            borderTop: "1px solid transparent",
          },
          icon: {
            color: theme.palette.primary.light,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: theme.palette.primary.light,
          },
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.light,
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            "&.Mui-checked+.MuiSwitch-track": {
              backgroundColor: theme.palette.common.black,
            },

            "&.Mui-checked .MuiSwitch-thumb": {
              backgroundColor: theme.palette.secondary.main,
            },
          },
          thumb: {
            backgroundColor: theme.palette.primary.light,
          },
          track: {
            backgroundColor: theme.palette.common.black,
          },
        },
      },
    },
  };
  return createTheme(theme, components);
}

// const darkPalette: ThemeOptions = {
//     palette: {
//       mode: "dark",
//       primary: {
//         main: "#657197",
//         dark: "#3B4668",
//         light: "#BAC4E2",
//         contrastText: "#ffffff",
//       },
//       secondary: {
//         main: "#18d2ff",
//         light: "#18d2ff",
//         dark: "#256faf",
//       },
//   background: {
//     default: "#2d3855",
//     paper: "#2d3855",
//   },
//   common: {
//     black: "#000000",
//     white: "#ffffff",
//   },
//   text: {
//     primary: "#bac4e2",
//   },
//   error: {
//     main: "#ea719d",
//     dark: "#7F5072",
//   },
//   warning: {
//     main: "#a961e4",
//     dark: "#564A94",
//   },
//       info: {
//         main: "#20a0ff",
//         dark: "#256FAF",
//       },
//       success: {
//         main: "#2ec495",
//         dark: "#2D8376",
//       },
//     },
//   };

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#ffec00",
    },
    secondary: {
      main: "#9c62ff",
    },
  },
};

export const darkTheme = createComponents(createTheme(baseTheme, themeOptions));

export const defaultTheme = darkTheme;
