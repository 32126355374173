import React, { useState, FC, useContext } from "react";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";
// import Logo from "../../components/Logo";

//new name imports
import { Page } from "../../common/Page"
import { LoginForm } from "./LoginForm";
import { LoginHeader } from "./LoginHeader"
import { verifyInput } from "../../Utils/verifyInput"
import { ErrorMsg } from "../../components/ErrorMsg/ErrorMsg"
import { LoginRequest } from "../../data-transfer-objects/request/login-request.dtc"
import { Container } from '../../common/Container'
import { Typography } from "../../common/Typography"
import { StoreContext } from "../../store.context"
import { observer } from "mobx-react-lite";

interface LoginPageProps {
  onLoginSuccess: () => void
}

export const LoginPage: FC<LoginPageProps> = observer(({ onLoginSuccess }) => {

  const { authStore } = useContext(StoreContext)

  const handleLogin = () => {
    authStore.setError("")
    onLoginSuccess()
  }

  return (
    <Page className={styles["login-page"]} >
      <LoginHeader />
      <LoginForm
        isLoading={authStore.isLoading}
        onChangeEmail={authStore.setLoginEmail}
        onChangePassword={authStore.setLoginPassword}
        onSubmit={handleLogin} />
      <ErrorMsg messsage={authStore.error} />
      <Container
        align="left"
        className={styles["login-page-link-container"]}
        orientation="horizontal"
      >
        <Typography type="h3">New here? </Typography>
        <Link className={styles["login-page-link"]} to="/signup">create an account</Link>
      </Container>
      <div className={styles.partner}>
        <img alt="aplus-svg" src="https://aplus-leasing.s3.amazonaws.com/static/images/logos/color.svg" />
      </div>
    </Page>
  );
}
)
