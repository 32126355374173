import React, { useState, useContext } from "react";
import CustomButton from "../CustomButton/CustomButton";
import Overlay from "../Overlay/Overlay";
import WheelContext from "../../Context/WheelContext";
import "./SearchOemWheel.css";
import { useHistory } from "react-router";

const SearchOemWheel = () => {
  const styles = {
    border: "1px solid #fff",
    marginTop: 20,
  };

  const history = useHistory();

  const W_CONTEXT = useContext(WheelContext);

  const [showOverlay, setShowOverlay] = useState(false);
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [showModelOverlay, setShowModelOverlay] = useState(false);
  const [width, setWidth] = useState("");
  const [diameter, setDiameter] = useState("");

  return (
    <div className="search__oem__wheel__container">
      <CustomButton
        styles={styles}
        color={"transparent"}
        onClickDo={() => setShowOverlay(true)}
        height={window.innerWidth > 900 ? 55 : 80 }
        width={"90%"}
        borderRadius={10}
      >
        {make === "" ? "Make" : make}
      </CustomButton>
      {showOverlay && (
        <Overlay
          list={W_CONTEXT!.carMake}
          show={showOverlay}
          onCloseBtn={() => setShowOverlay(false)}
          pickOption={(make) => {
            setMake(make);
            setModel("");
            setShowOverlay(false);
          }}
        />
      )}
      {make !== "" && (
        <CustomButton
          styles={styles}
          color={"transparent"}
          onClickDo={() => setShowModelOverlay(true)}
          height={window.innerWidth > 900 ? 55 : 80 }
          width={"90%"}
          borderRadius={10}
        >
          {model === "" ? "Model" : model}
        </CustomButton>
      )}
      {showModelOverlay && (
        <Overlay
          list={W_CONTEXT!.carModel[W_CONTEXT!.carMake.indexOf(make)]}
          show={showModelOverlay}
          onCloseBtn={() => setShowModelOverlay(false)}
          pickOption={(model) => {
            setModel(model);
            setShowModelOverlay(false);
          }}
        />
      )}
      <div className="add__oem__wheel__size__container">
        <input
          maxLength={2}
          placeholder="Diameter"
          className="add__oem__wheel__size__input"
          onChange={(ev) => setDiameter(ev.target.value)}
        />
        <p style={{ color: "#fff", fontSize: 20 }}>X</p>
        <input
          maxLength={3}
          placeholder="Width"
          className="add__oem__wheel__size__input"
          onChange={(ev) => setWidth(ev.target.value)}
        />
      </div>
      <CustomButton
        styles={styles}
        color={"transparent"}
        onClickDo={() =>
          history.push(
            `/results/oemwheels?location=broad&make=${make}&model=${model}&width=${width}&diameter=${diameter}`
          )
        }
        height={window.innerWidth > 900 ? 55 : 80 }
        width={"90%"}
        borderRadius={10}
      >
        Search
      </CustomButton>
    </div>
  );
};

export default SearchOemWheel;
