import { FC, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Route,
  Redirect
} from 'react-router-dom';

interface IPrivateRouteProps {
  children: ReactNode
  path: string
  authenticated: boolean
}

export const PrivateRoute: FC<IPrivateRouteProps> = observer(({ children, path, authenticated }) => {


  return (
    <Route
      exact path={path}
      render={
        ({ location }) => (
          
           authenticated ? (
            children
          ) : (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: location }
                }}
              />
            ))
      }
    />
  );
})

