import { Container } from "../../common/Container"
// import { Button } from "../../common/Button"
import { FormInput } from "../../components/FormInput/FormInput"
import styles from "./OEM.module.scss"
import { useHistory } from "react-router-dom"
import { FC } from "react"
import { TokenService } from "../../Services/token.service"

//New Import 
import { TextField, Button } from "@mui/material"

interface IOEMProps {
  interchange: string
  setInterchange: React.Dispatch<React.SetStateAction<string>>
}

export const OEMinputSearch: FC<IOEMProps> = ({
  interchange,
  setInterchange
}) => {
  const history = useHistory()
  return (
    <Container className={styles.interchange} orientation="vertical">
      <form onSubmit={(ev) => {
        ev.preventDefault()
        let interchangeValue = interchange;
        if (interchange.length === 4)
          interchangeValue = "0" + interchangeValue;
        history.push(
          `/results/oemwheels?interchange=${interchangeValue}&companyId=${TokenService.getCompanyId()}`
        );
      }} >
        <TextField
        onChange={(ev) => setInterchange(ev.target.value)}
          variant="outlined"
          label="560"
          sx={{
            width: "100%"
          }}
        />
        <Button
          type="submit"
          size="large"
          variant="contained"
          sx={{
            width: "100%"
          }}
        >
          Search
        </Button>
      </form>
    </Container>
  )
}