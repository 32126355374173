import jwtDecode from "jwt-decode";
import {config} from "../config";

let _timeoutId: number;
// const _TEN_SECONDS_IN_MS = 10000;

const TokenService = {
  saveAuthToken(token: string) {
    window.sessionStorage.setItem(config.TOKEN_KEY, token);
  },
  getAuthToken() {
    return window.sessionStorage.getItem(config.TOKEN_KEY);
  },
  clearAuthToken() {
    window.sessionStorage.removeItem(config.TOKEN_KEY);
  },
  hasAuthToken() {
    return !!TokenService.getAuthToken();
  },
  makeBasicAuthToken(email: string, password: string) {
    return window.btoa(`${email}:${password}`);
  },
  parseJwt(jwt: string ): string {
    return jwtDecode(jwt);
  },
  readJwtToken(): string | boolean {
    const token = TokenService.getAuthToken();
    return token ? TokenService.parseJwt(token) : false;
  },
  // _getMsUntilExpiry(payload) {
  //   return payload.exp * 1000 - Date.now();
  // },
  // queueCallbackBeforeExpiry(callback) {
  //   /* get the number of ms from now until the token expires */
  //   const msUntilExpiry = TokenService._getMsUntilExpiry(
  //     TokenService.readJwtToken()
  //   );

  //   _timeoutId = setTimeout(callback, msUntilExpiry - _TEN_SECONDS_IN_MS);
  // },
  clearCallbackBeforeExpiry() {
    clearTimeout(_timeoutId);
  },
  getUserName() {
    return window.localStorage.getItem(config.USER_NAME);
  },
  getUserId() {
    return window.localStorage.getItem(config.USER_ID);
  },
  getSubId() {
    return window.localStorage.getItem(config.SUBSCRIPTION_ID);
  },
  saveUserName(user_name: string) {
    window.localStorage.setItem(config.USER_NAME, user_name);
  },
  saveUseremail(user_email: string) {
    window.localStorage.setItem(config.USER_EMAIL, user_email);
  },
  getUserEmail() {
    return window.localStorage.getItem(config.USER_EMAIL);
  },
  saveSubId(subid: string) {
    window.localStorage.setItem(config.SUBSCRIPTION_ID, subid);
  },
  saveUserId(user_id: string) {
    window.localStorage.setItem(config.USER_ID, user_id);
  },
  saveSubState(subState: string){
    window.localStorage.setItem(config.SUB_STATE, subState)
  },
  getSubState(){
    return window.localStorage.getItem(config.SUB_STATE)
  }

  
};

export default TokenService;
