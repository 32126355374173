import { Section } from "../../common/Section";
import { Form } from "../../common/Form";
import { FormInput } from "../../components/FormInput/FormInput";
import { FC } from "react";
import { Button } from "../../common/Button";
import { Loading } from "../../common/Loading"

interface IManagerInfoFormProps {
    setUserName: (_: string) => void
    setUserEmail: (_: string) => void
    setPassword: (_: string) => void
    setConfirmPassword: (_: string) => void
    onSubmit: () => void
    isLoading: boolean
}

export const ManagerInfoForm: FC<IManagerInfoFormProps> = ({
    setUserName,
    setUserEmail,
    setPassword,
    setConfirmPassword,
    onSubmit,
    isLoading
}) => {
    return (
        <Section>
            <Form onSubmit={onSubmit}>
                <FormInput placeholder="Full name" name="manager-name" orientation="vertical" onChange={setUserName} />
                <FormInput placeholder="Email" name="manager-email" orientation="vertical" onChange={setUserEmail} />
                <FormInput placeholder="Password" type="password" name="password" orientation="vertical" onChange={setPassword} />
                <FormInput placeholder="Confirm Password" type="password" name="confirm-password" orientation="vertical" onChange={setConfirmPassword} />
                {isLoading ? <Loading /> : <Button submitButton size="large" type="primary">
                    Next
                </Button>}
            </Form>
        </Section>
    )
}