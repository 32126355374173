import React, { FC, useState, useEffect, useContext } from "react";
import "./SearchPage.css"
import ButtonContainer from "../../components/ButtonContainer/ButtonContainer";
import TireSeachComponent from "../../components/TireSearchComponent/TireSearchComponent";
import SearchWheelComponent from "../../components/SearchWheelComponent/SearchWheelComponent";
import WheelContext from "../../Context/WheelContext"
import WheelApiService from "../../Services/wheel-api-service";

//NEw

import { AppPage } from "../../common/AppPage"

const SearchPage: FC = () => {
  const [active, setActive] = useState("");

  const W_CONTEXT = useContext(WheelContext)

  const getWheelPArameters = () => {
    WheelApiService.getWheelParameters().then((res) => {
      const { carMake, carModel, years, boltPatter } = res;
      W_CONTEXT!.setWheelParameters(carMake, carModel, years, boltPatter);
    });
  };

  useEffect(() => {
    getWheelPArameters()
  }, [])

  return (
    <AppPage>
    <div className="search__page">
      <ButtonContainer setActive={setActive} active={active} />
      { active === "tires" && <TireSeachComponent/> }
      { active === "wheels" && <SearchWheelComponent /> }
    </div>
    </AppPage>
  );
};

export default SearchPage;
