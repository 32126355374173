import React, { FC } from "react"
import "./Pagination.css";

interface PaginationProps {
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  size: number
}

const Pagination: FC<PaginationProps> = (props) => {
  const { currentPage, setCurrentPage } = props;

  const populatePagination = () => {
    let data = [];

    for (let i = 0; i < props.size; i++) {
      data.push(
        <li
          onClick={() => setCurrentPage(i + 1)}
          className="pagination__li"
          style={{
            backgroundColor: i + 1 === currentPage ? "white" : "transparent",
          }}
        >
          {i + 1}
        </li>
      );
    }

    if (props.size > 5) {
      let start = 0;
      if (currentPage > props.size - 5) {
        start = props.size - 5;
      } else {
        start = currentPage - 1;
      }
      return data.slice(start, currentPage + 4);
    }

    return data;
  };

  return (
    <div className="pagination__container">
      {props.size >= 1 && (
        <ul className="pagination__ul">
          {currentPage !== 1 ? (
            <li
              onClick={() => {
                if (currentPage === 1) {
                  return;
                }
                setCurrentPage(currentPage - 1);
              }}
              id="previus__btn"
            >
              {" "}
              {" < "}{" "}
            </li>
          ) : (
            ""
          )}
          {populatePagination()}
          {currentPage !== props.size ? (
            <li
              onClick={() => {
                if (currentPage === props.size) {
                  return;
                }
                setCurrentPage(currentPage + 1);
              }}
              id="next__btn"
            >
              {" "}
              {" > "}
            </li>
          ) : (
            ""
          )}
        </ul>
      )}
    </div>
  );
};

export default Pagination;
