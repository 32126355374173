import React, { useEffect, useState, FC } from "react";
import "./CompanyInfoComponent.css";
import CompanyApiService from "../../Services/company-api-service";

interface IParams {
  companyId: string
}

const CompanyInfoComponent: FC<IParams> = ({ companyId }) => {

  const [companyName, setCompanybName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyState, setCompanyState] = useState("");
  const [companyZipcode, setCompanyZipcode] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");

  useEffect(() => {
    console.log(companyId)
    CompanyApiService.getCompanyInfo(companyId).then((company) => {
      setCompanybName(company.company_name);
      setCompanyAddress(company.company_address);
      setCompanyCity(company.company_city);
      setCompanyState(company.company_state);
      setCompanyZipcode(company.company_zipcode);
      setCompanyPhone(company.company_phonenumber);
    });
  }, [companyId]);

  return (
    <div className="company__info__contaier">
      <p
        style={{ fontWeight: "600", fontSize: 25 }}
        className="company__info__text"
      >
        {companyName}
      </p>
      <p className="company__info__text">{companyAddress}</p>
      <p className="company__info__text">{`${companyCity}, ${companyState}, ${companyZipcode}`}</p>
      <p className="company__info__text">{`(${companyPhone.slice(
        0,
        3
      )}) ${companyPhone.slice(3, 6)}-${companyPhone.slice(6)}`}</p>
    </div>
  );
};

export default CompanyInfoComponent;
