import React, { FC, useLayoutEffect, useContext } from "react";
import "./SettingsPage.css";
import Avatar from "react-avatar";
import profileImg from "../../images/profile.png";
import CustomButton from "../../components/CustomButton/CustomButton";
// import TokenService from "../../Services/token-service";
import { useHistory } from "react-router";
import { AppPage } from "../../common/AppPage"

//New imports

import { Button } from "@mui/material"
import { observer } from "mobx-react-lite"
import { StoreContext } from "../../store.context"
import { TokenService } from "../../Services/token.service"

interface ISettingsPageProps {
  logOut: () => void
}

const SettingsPage: FC<ISettingsPageProps> = observer((props) => {
  const styles = {
    border: "1px solid #fff",
    fontSize: "20px",
    marginTop: "10px",
  };

  const history = useHistory();
  const { organizationStore } = useContext(StoreContext)

  useLayoutEffect(() => {
    organizationStore.getOrganizationsDetails(TokenService.getCompanyId()!)
  }, []);


  return (
    <AppPage>
      <div className="settings__page">
        <Avatar name={TokenService.getCompanyName()!} size="125" round={true} />
        <Button
          variant="outlined"
          size="large"
          sx={{
            width: "100%",
            marginTop: 2

          }}
        >
          Manage Account
        </Button>
        {/* <CustomButton
          color={"transparent"}
          width={"90%"}
          height={65}
          borderRadius={10}
          styles={styles}
          addClass="settings__page__button"
          onClickDo={() => history.push("/updatePayment")}

        >
          Manage Account
        </CustomButton> */}
        <Button
          variant="outlined"
          size="large"
          onClick={() => history.push("/editCompany")}
          sx={{
            width: "100%",
            marginTop: 2

          }}
        >
          Edit Company
        </Button>

        {/* <CustomButton
          color={"transparent"}
          width={"90%"}
          height={65}
          borderRadius={10}
          styles={styles}
          onClickDo={() => history.push("/updatePassword")}
        >
          Change Password
        </CustomButton> */}
        <Button
          variant="outlined"
          size="large"
          onClick={() => {
            props.logOut();
            history.push("/");
          }}
          sx={{
            width: "100%",
            marginTop: 2

          }}
        >
          Sign Out
        </Button>

      </div>
    </AppPage>
  );
})

export default SettingsPage;
