
import { FC } from "react"
import styles from "./Logo.module.scss"

interface ILogoProps {
    size: "large" | "medium" | "small"
}

export const Logo: FC<ILogoProps> = ({ size }) => {
    return (
        <div className={`${styles.logo} ${styles[`logo-${size}`]}`}>
            <img
                alt="360-automotive-icon"
                src="https://res.cloudinary.com/redlineinventory/image/upload/v1644541996/logo_rs54no.png" />
        </div>
    )
}