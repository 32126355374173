import { AppPage } from "../../common/AppPage"
import financingHero from "../../images/financing_one.jpg"
import { Container } from "../../common/Container"
import styles from "./index.module.scss"
export const FinancingPage = () => {




    return (
        <AppPage className={styles["main-financing"]}>
            <Container justifyContent="space-between" className={styles.financing} orientation="horizontal">
                <img alt="aplus-svg" className={styles["financing-logo"]} src="	https://aplus-leasing.s3.amazonaws.com/static/images/logos/color.svg" />
                <a
                    target={"_blank"}
                    href="https://aplusleasing.com/what-is-lo/"
                    className={styles["financing-apply"]} rel="noreferrer">
                    Apply for lease now</a>
            </Container >
            <div className={styles["financing-text"]}>
                <p>Buy now, pay later.</p>
                <img alt="hero" width={"100%"} src={financingHero} />
                <a
                    target={"_blank"}
                    href="https://aplusleasing.com/what-is-lo/"
                    className={styles["financing-btn"]} rel="noreferrer">
                    Apply for a lease now
                </a>
            </div>
        </AppPage>
    )
}

