import React, { SetStateAction } from "react";
import "./ButtonContainer.css";
import CustomButton from "../CustomButton/CustomButton";
import colors from "../../Constants/colors";

//MUI Imports 

import { Button } from "@mui/material"

interface ButtonContainerProps {
  active: string
  setActive: React.Dispatch<SetStateAction<string>>
}

const ButtonContainer: React.FC<ButtonContainerProps> = ({ active, setActive }) => {
  return (
    <div className="add__buttons__container">
      <Button
        sx={{ width: "40%" }}
        size="large"
        variant={active === "tires" ? "contained" : "outlined"}
        onClick={() => setActive("tires")}
      >Tires</Button>
      <Button 
      sx={{ width: "40%" }} 
      size="large" 
      variant={active === "wheels" ? "contained" : "outlined"}
      
        onClick={() => setActive("wheels")}
      >Wheels</Button>
      {/* <CustomButton
        color={props.active === "tires" ? colors.activeGray : colors.grayAccent}
        borderRadius={10}
        height={50}
        width={"40%"}
        onClickDo={() => props.setActive("tires")}
      >
        Tires
      </CustomButton>
      <CustomButton
        color={
          props.active === "wheels" ? colors.activeGray : colors.grayAccent
        }
        borderRadius={10}
        height={50}
        width={"40%"}
        onClickDo={() => props.setActive("wheels")}
      >
        Wheels
      </CustomButton> */}
    </div>
  );
};

export default ButtonContainer;
