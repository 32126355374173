// interface input {
//     value: string
// }
// interface inputs {
//     input: value[]
// }

export const verifyInput = (_: (string| undefined)[]): string => {
  const value = _.map((_) => {
    if(_ === undefined) return "empty"
    if (_.length <= 0)  return "empty";
    return true;
  });

  return value.indexOf("empty") >= 0 ? "Complete missing fields" : "";
};
