import { config } from "../config";

export const TokenService = {
  saveAuthToken: (token: string) => {
    localStorage.setItem(config.TOKEN_KEY, token);
  },
  clearAuthToken: () => {
    localStorage.removeItem(config.TOKEN_KEY);
  },
  getAuthToken: () => {
    return localStorage.getItem(config.TOKEN_KEY);
  },
  saveCompanyId: (id: string) =>
    localStorage.setItem(config.ORGANIZATION_KEY, id),

  getCompanyId: () => localStorage.getItem(config.ORGANIZATION_KEY),

  getCompanyName: () => localStorage.getItem(config.ORGANIZATION_NAME),
  saveCompanyName: (name: string) => {
    localStorage.setItem(config.ORGANIZATION_NAME, name);
  },
  saveSubscriptionId: (id: string) => {
    localStorage.setItem(config.SUBSCRIPTION_ID, id);
  },
  saveUserEmail: (email: string) => {
    localStorage.setItem(config.USER_EMAIL, email);
  },
};
