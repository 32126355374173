const REGEX_UPPER_LOWER_NUMBER_SPECIAL =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&])[\S]+/;

export const validatePassword = (password: string | undefined) => {
  if (password === undefined) {
    return "Complete missing fields";
  }
  if (password.length < 8) {
    return "Password must be longer than 8 characters";
  }
  if (password.length > 30) {
    return "Password must be less than 30 characters";
  }
  if (password.startsWith(" ") || password.endsWith(" ")) {
    return "Password must not start or end with empty spaces";
  }
  if (!REGEX_UPPER_LOWER_NUMBER_SPECIAL.test(password)) {
    return "Password must contain 1 upper case, lower case, number and special character";
  }
  return null;
};
