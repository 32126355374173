import { FC } from "react"
import Card from "../../components/Card/Card"
import styles from "./ItemCard.module.scss"
import { Typography } from "../../common/Typography"

interface IItemCardProps {
    onClick: () => void
    imageSrc: string
    label: string
}
export const ItemCard: FC<IItemCardProps> = ( {
    onClick, imageSrc, label
}) => {
    return(
        <Card
        height="100"
        width={"90%"}
        onClickDo={onClick}
      >
        <div className={styles["card-container"]}>
          <Typography type="h4" >{label}</Typography>
          <img height={80} src={imageSrc} alt="Tire" />
        </div>
      </Card>
    )
}