import React, { FC } from "react"
import "./PageNotFound.css"
import { BiError } from "react-icons/bi"

const PageNotfound: FC = () => {
    return(
        <div className="page__not__found">
            <BiError color="white" size={180}/>
            <h1 style={{color: "#fff"}}>ooops, Wrong way!</h1>
        </div>
    )
}

export default PageNotfound;