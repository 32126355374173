import React from "react";
import "./VerificationPage.css";

const VerificationPage = () => {
  return (
    <div className="verification__page__container">
      <img
        alt="logo"
        className="verification__img"
        src="https://res.cloudinary.com/redlineinventory/image/upload/v1631595643/logo_yi58xt.png"
      />
      <div className="verification__page__info">
        <p className="verification__page__text">
          Your account has been successfully created. Our team of experts will
          verify your information and will get back to you with the next steps
          in the next 24 hours
        </p>
        <a href="https://www.360automotiveparts.com/" className="verification__anchor">Go to website</a>
      </div>
    </div>
  );
};

export default VerificationPage;
