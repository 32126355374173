import React, { useState, useEffect, useContext } from "react";
import "./App.scss";
import { Route, Switch, useHistory } from "react-router-dom";
import { LoginPage } from "./Routes/LoginPage/index";
import { SignUpPage } from "./Routes/SignUpPage/index";
import CheckoutFormPage from "./Routes/CheckoutFormPage/CheckoutFormPage";
import PageNotfound from "./Routes/PageNotFound/PageNotFound";
import SuccessPage from "./Routes/SuccessPage/SuccessPage";
import VerificationPage from "./Routes/VerificationPage/VerificationPage";
import OnBoard from "./Routes/OnBoard/OnBoard";
import TokenService from "./Services/token-service";
import { ResetPassword } from "./Routes/ResetPassword/index";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { config } from "./config";
import SubscriptionApiService from "./Services/subscription-api-service";
import { LoginRequest } from "./data-transfer-objects/request/login-request.dtc";
import { StoreContext } from "./store.context";
import { observer } from "mobx-react-lite";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { HomePage } from "./Routes/HomePage/index";
import { SideMenu } from "./components/SideMenu/SideMenu";
import ChatPage from "./Routes/ChatPage/ChatPage";
import AddPage from "./Routes/AddPage/AddPage";
import SearchPage from "./Routes/SearchPage/SearchPage";
import SettingsPage from "./Routes/SettingsPage/SettingsPage";
import ResultsPage from "./Routes/ResultsPage/ResultsPage";
import { InventoryPage } from "./Routes/InventoryPage/index";
import { Menu } from "./components/Menu/Menu";
import DetailPage from "./Routes/DetailPage/DetailPage";
import { FinancingPage } from "./Routes/Financing";
import EditCompanyInfo from "./Routes/EditCompanyInfo/EditCompanyInfo";

const stripePromise = loadStripe(config.ST_KEY);

interface AutomotivePart {
  part: "tire" | "oemwheels" | "customwheels";
}

const App = observer(() => {
  const { authStore, userStore } = useContext(StoreContext);
  const authenticated = authStore.isAuthenticated();

  const [isUserAuth, setIsUserAuth] = useState(false);

  const checkSubscription = () => {
    return new Promise((resolve, reject) => {
      SubscriptionApiService.verifyIfSubscriptionActive(
        TokenService.getSubId()!
      ).then((res) => {
        if (res.plan.active) {
          TokenService.saveSubState("trial");
          setIsUserAuth(!!TokenService.readJwtToken());
          resolve("active");
        } else {
          TokenService.saveSubState("complete");
          reject("not Active");
        }
      });
    });
  };

  useEffect(() => {
    let token: boolean = !!TokenService.readJwtToken();
    setIsUserAuth(token);
  }, []);

  const history = useHistory();

  const logOut = () => {
    authStore.logOut();
    history.push("/");
  };

  const onLoginSuccess = async () => {
    authStore.setError("");
    authStore.setIsLoading(true);
    await authStore.login();
    authStore.setIsLoading(false);
    if (authStore.subscriptionStatus === "newMember") {
      history.push("/subscribe");
      return;
    }
    history.push("/inventory");
  };

  const onAccountCreated = async () => {
    const successful = await userStore.createUser();
    if (successful) history.push("/");
  };

  return (
    <div className="App">
      <Switch>
        <Route exact path="/">
          {authStore.authenticated ? (
            <HomePage />
          ) : (
            <LoginPage onLoginSuccess={onLoginSuccess} />
          )}
        </Route>
        <Route exact path="/signup">
          <SignUpPage onAccountCreated={onAccountCreated} />
        </Route>
        <Route exact path="/verification">
          <VerificationPage />
        </Route>
        <Route exact path="/success">
          <SuccessPage
            msg="You have successfully created an account please go ahead and log in."
            route="/"
            btnTitle="Login"
          />
        </Route>
        <Route exact path="/paymentsuccess">
          <SuccessPage
            msg="You have successfully added a payment method, please go ahead and log in your account ."
            route="/"
            btnTitle="Login"
          />
        </Route>
        <Route exact path="/help">
          <OnBoard />
        </Route>
        <Route exact path="/subscribe">
          <Elements stripe={stripePromise}>
            <CheckoutFormPage />
          </Elements>
        </Route>
        <Route exact path="/resetPassword">
          <ResetPassword />
        </Route>
        <Route exact path="/items/:partType/:itemId">
          <DetailPage />
        </Route>
        <Route exact path="/editItem/:partType/:itemId">
          <AddPage />
        </Route>
        <PrivateRoute authenticated={authenticated} path="/inventory/:carPart">
          <InventoryPage />
        </PrivateRoute>
        <PrivateRoute authenticated={authenticated} path="/financing">
          <FinancingPage />
        </PrivateRoute>
        <PrivateRoute authenticated={authenticated} path="/results/:partType">
          <ResultsPage />
        </PrivateRoute>
        <PrivateRoute authenticated={authenticated} path="/inventory">
          <HomePage />
        </PrivateRoute>
        <PrivateRoute authenticated={authenticated} path="/chat">
          <ChatPage />
        </PrivateRoute>
        <PrivateRoute authenticated={authenticated} path="/addItem">
          <AddPage />
        </PrivateRoute>
        <PrivateRoute authenticated={authenticated} path="/search">
          <SearchPage />
        </PrivateRoute>
        <PrivateRoute authenticated={authenticated} path="/search">
          <SearchPage />
        </PrivateRoute>
        <PrivateRoute authenticated={authenticated} path="/editCompany">
          <EditCompanyInfo />
        </PrivateRoute>
        <PrivateRoute authenticated={authenticated} path="/settings">
          <SettingsPage logOut={logOut} />
        </PrivateRoute>
        <Route>
          <PageNotfound />
        </Route>
      </Switch>
      {authenticated && <SideMenu />}
      {authenticated && <Menu />}
    </div>
  );
});

export default App;
