import React from "react";
import "./CustomButton.css"

interface CustomButtonProps {
  onClickDo: () => any,
  color: string,
  height?: number,
  width?: string,
  borderRadius?: number,
  fontColor?: string,
  addClass?: string,
  type?: "button" | "submit" | "reset" | undefined,
  styles?: any,
  children: JSX.Element | string
}

const CustomButton = (props: CustomButtonProps) => {
  return (
    <>
      <button
        onClick={() => props.onClickDo()}
        style={{
          ...props.styles,
          ...{
            backgroundColor: props.color,
            height: props.height,
            width: props.width,
            borderRadius: props.borderRadius,
            color: props.fontColor
          },
        }}
        className={`custom__button ${props.addClass}`}
        type={props.type}
        
      >
        {props.children}
      </button>
    </>
  );
};

export default CustomButton;
