const Utils = {
  async getImageLink(image: Blob) {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "t7mam2n0");
    const res = await fetch(
      "https://api.cloudinary.com/v1_1/redlineinventory/image/upload",
      {
        method: "POST",
        body: formData,
      }
    );

    const file = await res.json();

    const { secure_url } = file;

    const link = await secure_url;

    return link;
  },
};

export default Utils;
