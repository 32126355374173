import { makeAutoObservable } from "mobx";
import { OEMWheelService } from "../Services/oemwheels.service";

export class OEMWheelStore {
  public myOEMWheels: any[] = [];
  public myInterchangeNumber: string = "";
  public myOEMWheelsByInterchange: any[] = [];
  public queryWheels: any[] = []

  constructor(private readonly oemWheelService: OEMWheelService) {
    makeAutoObservable(this);
  }

  getMyOwnOEMWheels = async () => {
    try {
      const response = await this.oemWheelService.getMyOEMWheels();
      console.log(response);
      this.setMyOEMWheels(response);
    } catch (error) {
      console.log(error);
    }
  };

  getMyOwnWheelsByInterchangeNumber = async (_: string) => {
    try {
      const response =
        await this.oemWheelService.getMyOEMWheelByInterchangeNumber(
          _ ? _ : this.myInterchangeNumber
        );
      this.setMyOEMWheelsByInterchange(response);
    } catch (error) {
      console.log(error);
    }
  };

  searchWheelsByModelAndSize = async (make: string, diameter: string, model: string, width: string) => {
    try {
      const response = await this.oemWheelService.queryOEMWheelsByModelAndSize(make, diameter, model, width)
      this.setMyOEMWheelsByInterchange(response)
    } catch (e) {
      throw e
    }
  }

  //Setters

  setQueryWheels = (_: any[]) => {
    this.queryWheels = _
  }

  setMyOEMWheelsByInterchange = async (_: any[]) => {
    this.myOEMWheelsByInterchange = _;
  };

  setMyInterchageNumber = (_: string) => {
    this.myInterchangeNumber = _;
  };

  setMyOEMWheels = (_: any[]) => {
    this.myOEMWheels = _;
  };
}
