import { useEffect, useState, FC, useContext } from "react";
import styles from "./index.module.scss";
import { useParams } from "react-router";
import WheelApiService from "../../Services/wheel-api-service";
import TiresApiService from "../../Services/tires-api-service";
import ResultBlock from "../../components/ResultBlock/ResultBlock";
import ReactLoading from "react-loading";
import { CgAddR } from "react-icons/cg";
import { useHistory } from "react-router";
import CustomButton from "../../components/CustomButton/CustomButton";
import colors from "../../Constants/colors";
import TokenService from "../../Services/token-service";
import Pagination from "../../components/Pagination/Pagination";

// new import

import { AppPage } from "../../common/AppPage";
import { StoreContext } from "../../store.context";
import { observer } from "mobx-react-lite";
import { Header } from "./Header";
import { OEMinputSearch } from "./OEMinputSearch";
import { Container } from "../../common/Container";
import { TireSearch } from "./TireSearch";
import { BsFilter } from "react-icons/bs"

//MUI

import { Box, TextField } from "@mui/material"
import { height } from "@mui/system";

export type IParams = {
  carPart: "tires" | "oemwheels" | "customwheels";
};

export const InventoryPage: FC = observer(() => {
  const { tireStore, customWheelStore, oemWheelStore } =
    useContext(StoreContext);

  const { carPart } = useParams<IParams>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [perPage] = useState(window.innerWidth > 1400 ? 12 : 9);
  const [currentPage, setCurrentPage] = useState(1);
  const [interchange, setInterchange] = useState("");


  const [makeFilter, setMakeFilter] = useState("")
  const [showFilter, setShowFilter] = useState(false)

  //new

  const getCartParts = () => {
    if (carPart === "tires") {
      tireStore.getMyOwnTires();
      return;
    }
    if (carPart === "customwheels") {
      customWheelStore.getMyCustomWheels();
      return;
    }
    if (carPart === "oemwheels") {
      console.log("here");
      oemWheelStore.getMyOwnOEMWheels();
      return;
    }
  };

  useEffect(() => {
    getCartParts();
  }, [currentPage]);

  const populateInventory = () => {
    let startDatafrom = (currentPage - 1) * perPage;
    let endDataAt = currentPage * perPage;
    let it: any[] = [];
    if (carPart === "customwheels") {
      it = customWheelStore.myCustomWheels.map(
        (
          wheel: {
            id: string;
            brand: string;
            wheel_diameter: string;
            wheel_width: string;
            wheel_description: string;
            img: string;
            company_id: string;
          },
          idx
        ) => (
          <ResultBlock
            key={idx}
            id={wheel.id}
            type={"customWheels"}
            title={`${wheel.brand} ${wheel.wheel_diameter} X ${wheel.wheel_width}`}
            description={wheel.wheel_description}
            imageSrc={wheel.img}
            companyId={wheel.company_id}
          />
        )
      );
    } else if (carPart === "oemwheels") {
      if (makeFilter.length > 1) {
        it = oemWheelStore.myOEMWheels.filter(_ => _.make.includes(makeFilter.toUpperCase()))
        it = it.map(
          (
            wheel: {
              id: string;
              make: string;
              model: string;
              wheel_diameter: string;
              wheel_width: string;
              wheel_description: string;
              img: string;
              company_id: string;
            },
            idx
          ) => {
            if (wheel.make.includes(makeFilter))
              return <ResultBlock
                key={idx}
                id={wheel.id}
                type={"oemWheels"}
                title={`${wheel.make} ${wheel.model} ${wheel.wheel_diameter} X ${wheel.wheel_width} `}
                description={wheel.wheel_description}
                imageSrc={wheel.img}
                companyId={wheel.company_id}
              />
          })
        console.log(it)
      } else {
        it = oemWheelStore.myOEMWheels.map(
          (
            wheel: {
              id: string;
              make: string;
              model: string;
              wheel_diameter: string;
              wheel_width: string;
              wheel_description: string;
              img: string;
              company_id: string;
            },
            idx
          ) => (
            <ResultBlock
              key={idx}
              id={wheel.id}
              type={"oemWheels"}
              title={`${wheel.make} ${wheel.model} ${wheel.wheel_diameter} X ${wheel.wheel_width} `}
              description={wheel.wheel_description}
              imageSrc={wheel.img}
              companyId={wheel.company_id}
            />
          )
        );
      }
    } else if (carPart === "tires") {
      it = tireStore.myTires.map(
        (
          tire: {
            id: string;
            size: string;
            brand: string;
            details: string;
            img: string;
            company_id: string;
          },
          idx
        ) => (
          <ResultBlock
            key={idx}
            id={tire.id}
            type={"tires"}
            title={`${tire.size} ${tire.brand}`}
            description={tire.details}
            imageSrc={tire.img}
            companyId={tire.company_id}
          />
        )
      );
    }
    return it.slice(startDatafrom, endDataAt);
  };

  return (
    <AppPage>
      <div className="inventory__page">
        <Container
          className={styles["inventory-header"]}
          orientation="vertical"
        >
          <Header cartPart={carPart} />
          {carPart === "oemwheels" && (
            <Box sx={{
              borderBottom: "1px solid white",
              paddingBottom: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end"

            }}>
              <OEMinputSearch
                interchange={interchange}
                setInterchange={setInterchange}
              />
              <Box sx={{
                display: "flex",
                height: 58
              }}>
                {showFilter &&
                  <TextField
                    label="MAKE"
                    variant="outlined"
                    onChange={(ev) => setMakeFilter((ev.target.value).toUpperCase())}
                    sx={{
                      marginRight: 2
                    }}
                  />}
                <Box
                  onClick={() => setShowFilter(!showFilter)}
                  sx={{

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 40,
                    width: 40,
                    borderRadius: 25,
                    ":hover": {
                      backgroundColor: "#3a3b3c",
                      cursor: "pointer"
                    }
                  }}>
                  <BsFilter size={24} color="white" />
                </Box>
              </Box>
            </Box>
          )}
          {carPart === "tires" && (
            <TireSearch
              setTireSize={tireStore.setMyOwnTireSize}
              tireSize={tireStore.myOwnTireSize}
            />
          )}
        </Container>

        {isLoading ? (
          <ReactLoading height={50} width={50} />
        ) : (
          <Container
            orientation="vertical"
            align="center"
            justifyContent="center"
            className={styles["inventory-results-contianer"]}
          >
            {populateInventory()}
            <Pagination
              size={
                carPart === "tires"
                  ? Math.ceil(tireStore.myTires.length / perPage)
                  : carPart === "oemwheels"
                    ? Math.ceil(oemWheelStore.myOEMWheels.length / perPage)
                    : carPart === "customwheels"
                      ? Math.ceil(customWheelStore.myCustomWheels.length / perPage)
                      : 0
              }
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            {((carPart === "tires" && tireStore.myTires.length < 1) ||
              (carPart === "oemwheels" &&
                oemWheelStore.myOEMWheels.length < 1) ||
              (carPart === "customwheels" &&
                customWheelStore.myCustomWheels.length < 1)) && (
                <div className="inventory__no__results__container">
                  <h3 style={{ color: "#fff" }}>
                    Nothing yet... Start adding items{" "}
                  </h3>
                  <CgAddR
                    className="inventory__add__icon"
                    onClick={() => history.push("/addItem")}
                    color={"#fff"}
                    size={40}
                  />
                </div>
              )}
          </Container>
        )}
      </div>
    </AppPage>
  );
});

export default InventoryPage;
