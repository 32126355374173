import React, { useState, useEffect, useContext } from "react";
import "./AddOemWheel.css";

import CustomButton from "../CustomButton/CustomButton";
import Overlay from "../Overlay/Overlay";

import { AiFillCloseCircle } from "react-icons/ai";

import Utils from "../../Utils/Utils";

import WheelApiService from "../../Services/wheel-api-service";
import WheelContext from "../../Context/WheelContext";
// import TokenService from "../../Services/token-service";
import { useHistory } from "react-router";
import ReactLoading from "react-loading";

//New imports
import { TokenService } from "../../Services/token.service";
import { useParams } from "react-router-dom";
type oemParam = {
  itemId: string;
};

const AddOemWheel = () => {
  const W_CONTEXT = useContext(WheelContext);

  const { itemId } = useParams<oemParam>();

  const history = useHistory();
  const styles = {
    border: "1px solid #fff",
    marginTop: 20,
  };

  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [diameter, setDiameter] = useState("");
  const [width, setWidth] = useState("");
  const [quantity, setQuantity] = useState("");
  const [image, setImage] = useState<Blob | null>();
  const [details, setDetails] = useState("");
  const [interchange, setInterchange] = useState("");
  const [spot, setSpot] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [editImage, setEditImage] = useState();
  useEffect(() => {
    if (itemId) {
      WheelApiService.getOemWheelById(itemId).then((res) => {
        setMake(res[0].make);
        setModel(res[0].model);
        setYear(res[0].car_year);
        setQuantity(res[0].quantity);
        setDiameter(res[0].wheel_diameter);
        setWidth(res[0].wheel_width);
        setEditImage(res[0].img);
        setInterchange(res[0].interchange);
        setSpot(res[0].spot);
        setImage(res[0].img);
        setDetails(res[0].wheel_description);
        console.log(res[0]);
        return;
      });
    }
    setModel("");
    setYear("");
    setQuantity("");
    setDiameter("");
    setWidth("");
    setImage(null);
  }, [make]);

  const [showOverlay, setShowOverlay] = useState(false);
  const [showModelOverlay, setShowModelOverlay] = useState(false);
  const [showYearOverlay, setShowYearOverlay] = useState(false);

  const addNewOemWheel = async () => {
    setIsLoading(true);

    let link = "";
    if (image) {
      link = await Utils.getImageLink(image);
    }

    const company_id = TokenService.getCompanyId() as string;

    let newOemWheel = {
      make: make,
      model: model,
      car_year: year,
      wheel_width: width,
      wheel_diameter: diameter,
      quantity: quantity,
      wheel_description: details,
      img: link,
      spot,
      interchange: interchange,
      company_id,
    };

    console.log(newOemWheel);
    WheelApiService.addOemWheel(newOemWheel)
      .then((res) => {
        if (res.status != 201) {
          setIsLoading(false);
          setErr(true);
          return;
        }
        setIsLoading(false);
        history.push("/success");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return isLoading ? (
    <div className="loading__overlay">
      <ReactLoading height={80} width={80} />
    </div>
  ) : (
    <div className="add__wheel__oem__container">
      <p className="add__wheel__oem__title">Add OEM Wheel</p>
      <div className="add__wheel__oem__form__container">
        <CustomButton
          styles={styles}
          color={"transparent"}
          onClickDo={() => setShowOverlay(true)}
          height={window.innerWidth > 900 ? 55 : 80}
          width={"90%"}
          borderRadius={10}
        >
          {make === "" ? "Make" : make}
        </CustomButton>
        {showOverlay && (
          <Overlay
            list={W_CONTEXT!.carMake}
            show={showOverlay}
            onCloseBtn={() => setShowOverlay(false)}
            pickOption={(make: string) => {
              setMake(make);
              setShowOverlay(false);
            }}
          />
        )}
        {make !== "" && (
          <CustomButton
            styles={styles}
            color={"transparent"}
            onClickDo={() => setShowModelOverlay(true)}
            height={window.innerWidth > 900 ? 55 : 80}
            width={"90%"}
            borderRadius={10}
          >
            {model === "" ? "Model" : model}
          </CustomButton>
        )}
        {showModelOverlay && (
          <Overlay
            list={W_CONTEXT!.carModel[W_CONTEXT!.carMake.indexOf(make)]}
            show={showModelOverlay}
            onCloseBtn={() => setShowModelOverlay(false)}
            pickOption={(model: string) => {
              setModel(model);
              setShowModelOverlay(false);
            }}
          />
        )}

        <CustomButton
          styles={styles}
          color={"transparent"}
          onClickDo={() => setShowYearOverlay(true)}
          height={window.innerWidth > 900 ? 55 : 80}
          width={"90%"}
          borderRadius={10}
        >
          {year === "" ? "Year" : year}
        </CustomButton>

        {showYearOverlay && (
          <Overlay
            list={W_CONTEXT!.carYear}
            show={showYearOverlay}
            onCloseBtn={() => setShowYearOverlay(false)}
            pickOption={(year: string) => {
              setYear(year);
              setShowYearOverlay(false);
            }}
          />
        )}

        <div className="add__oem__wheel__size__container">
          <input
            maxLength={2}
            placeholder="Diameter"
            className="add__oem__wheel__size__input"
            onChange={(ev) => setDiameter(ev.target.value)}
            value={diameter}
          />
          <p style={{ color: "#fff", fontSize: 20 }}>X</p>
          <input
            maxLength={3}
            placeholder="Width"
            className="add__oem__wheel__size__input"
            onChange={(ev) => setWidth(ev.target.value)}
            value={width}
          />
        </div>

        <input
          className="add__oem__wheel__qty__input"
          maxLength={2}
          placeholder="Quantity"
          onChange={(ev) => setQuantity(ev.target.value)}
          value={quantity}
        />
        <input
          className="add__oem__wheel__qty__input"
          maxLength={10}
          placeholder="Interchange"
          onChange={(ev) => setInterchange(ev.target.value)}
          value={interchange}
        />
        <input
          className="add__oem__wheel__qty__input"
          maxLength={10}
          placeholder="Location"
          onChange={(ev) => setSpot(ev.target.value)}
          value={spot}
        />
      </div>
      <div className="add__wheel__oem__details__container">
        <textarea
          onChange={(ev) => setDetails(ev.target.value)}
          name="details"
          placeholder="Details..."
          className="add__tire__description"
          value={details}
        />
      </div>

      <div
        className="add__Wheel__oem__image__container"
        style={!image ? styles : { border: 1 }}
      >
        {!image ? (
          <input
            className="add__Wheel__oem__picker"
            type="file"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setImage(e.target.files![0]);
            }}
          />
        ) : (
          <>
            <AiFillCloseCircle
              onClick={() => setImage(null)}
              className="add__wheel__oem__close__btn"
              size={30}
            />
            <img
              alt="wheel-OEM"
              className="add__wheel__oem__image"
              height={100}
              src={
                itemId !== undefined ? editImage : URL.createObjectURL(image)
              }
            />
          </>
        )}
      </div>

      <CustomButton
        width={"90%"}
        height={80}
        borderRadius={10}
        color={"transparent"}
        styles={{ ...styles, marginBottom: 20 }}
        onClickDo={() => addNewOemWheel()}
      >
        Done
      </CustomButton>
    </div>
  );
};

export default AddOemWheel;
