import {config} from "../config";
import TokenService from "./token-service";

const WheelApiService = {
  getWheelParameters() {
    return fetch(`${config.API_ENDPOINT}/oemwheels`).then((res) => res.json());
  },
  getAllOwnOEMWheels() {
    return fetch(
      `${
        config.API_ENDPOINT
      }/oemwheels/all?company_id=${TokenService.getUserId()}`
    ).then((res) => res.json());
  },
  getAllCustomWheels() {
    return fetch(
      `${
        config.API_ENDPOINT
      }/customwheels/all?company_id=${TokenService.getUserId()}`
    ).then((res) => res.json());
  },
  searchOemWheel(make: string, model: string, width: string, diameter: string) {
    return fetch(
      `${
        config.API_ENDPOINT
      }/oemwheels/search?make=${make}&model=${model}&width=${width}&diameter=${diameter}&companyId=${TokenService.getUserId()}`
    ).then((wheels) => wheels.json());
  },
  searchOemWheelInventory(model: string, width: string, diameter: string) {
    return fetch(
      `${
        config.API_ENDPOINT
      }/oemwheels/search?model=${model}&width=${width}&diameter=${diameter}&companyId=${TokenService.getUserId()}`
    ).then((wheels) => wheels.json());
  },
  searchCustomWheel(diameter: string, width: string, boltPattern: string) {
    return fetch(
      `${
        config.API_ENDPOINT
      }/customwheels/search?boltPattern=${boltPattern}&width=${width}&diameter=${diameter}&companyId=${TokenService.getUserId()}`
    ).then((wheels) => wheels.json());
  },
  addOemWheel(newWheel: {
    make: string;
    model: string;
    car_year: string;
    wheel_width: string;
    wheel_diameter: string;
    quantity: string;
    wheel_description: string;
    img: string;
    spot: string;
    interchange: string;
    company_id: string;
  }) {
    return fetch(`${config.API_ENDPOINT}/oemWheels/addoemWheel`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newWheel),
    });
  },
  addCustomWheel(newWheel: {
    brand: string,
    wheel_width: string,
    wheel_diameter: string,
    second_wheel_diameter: string,
    second_wheel_width: string,
    quantity: string,
    second_bolt_pattern: string,
    bolt_pattern: string,
    wheel_description: string,
    wheel_offset: string,
    img: string,
    company_id: string,
  }) {
    return fetch(`${config.API_ENDPOINT}/customWheels/addwheel`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newWheel),
    });
  },
  getCustomWheelById(customWheelId: string) {
    return fetch(
      `${config.API_ENDPOINT}/customWheels/details?customWheelId=${customWheelId}`
    ).then((wheels) => wheels.json());
  },
  deleteCustomWheel(customWheelId: string) {
    return fetch(
      `${config.API_ENDPOINT}/customWheels/delete?customWheelId=${customWheelId}`,{
        method: "DELETE",
      }
    );
  },
  getOemWheelById(wheelId: string) {
    return fetch(
      `${config.API_ENDPOINT}/oemWheels/details?wheelId=${wheelId}`
    ).then((wheels) => wheels.json());
  },
  getOemWheelByInterchangeInventory(interchange: string, companyId: string) {
    return fetch(
      `${config.API_ENDPOINT}/oemWheels/interchange?interchange=560-${interchange}&companyId=${companyId}`
    ).then((wheels) => wheels.json());
  },
  deleteOemWheel(wheelId: string) {
    return fetch(`${config.API_ENDPOINT}/oemWheels/delete?wheelId=${wheelId}`,
    {
      method: "DELETE",
    });
  },
  scanOemWheel(scanId: string) {
    return fetch(
      `${config.API_ENDPOINT}/oemWheels/scan?barcode=${scanId}`
    ).then((res) => res.json());
  },
};

export default WheelApiService;
