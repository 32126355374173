import { FC, ReactNode } from "react"
import { Page } from "./Page"
import styles from "./AppPage.module.scss"

interface IAppPageProps {
    children: ReactNode
    className?: string
}

export const AppPage: FC<IAppPageProps> = ({ children, className }) => {
    return <Page className={`${styles["app-page"]} 
    ${className === undefined ? "" : className}`} >{children}</Page>
}