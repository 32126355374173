import { Container } from "../../common/Container"
import { Typography } from "../../common/Typography"
import { FC } from "react"
import styles from "./ErrorMsg.module.scss"

interface IErrorMsg {
    messsage: string | boolean
}
export const ErrorMsg: FC<IErrorMsg> = ({ messsage }) => {
    return (
        <Container className={styles["error-container"]} orientation="horizontal" >
            {messsage && <Typography type="error">{messsage}</Typography>}
        </Container>
    )
}