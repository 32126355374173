import {config} from "../config";
import TokenService from "./token-service";

const CompanyApiService = {
  getCompanyInfo(company: string) {
    return fetch(
      `${config.API_ENDPOINT}/companies/getinfo?companyId=${company}`
    ).then((res) => res.json());
  },
  getCompanyInfoToUpdate() {
    return fetch(
      `${config.API_ENDPOINT}/companies/getinfo?companyId=${TokenService.getUserId()}`
    ).then((res) => res.json());
  },
  updateCompanyInfo(company:{
    company_name: string,
      company_address: string,
      company_city: string,
      company_state: string,
      company_zipcode: string,
      company_phonenumber: string,
      companyId: string,
  }) {
    return fetch(`${config.API_ENDPOINT}/companies/update`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(company),
    })
      .then((response) => {
        if (response.status !== 204) {
          return;
        }
      })
      .catch((err) => {});
  },
};

export default CompanyApiService;
