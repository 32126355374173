import {config} from "../config";
import TokenService from "./token-service";

const REGEX_UPPER_LOWER_NUMBER_SPECIAL =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&])[\S]+/;

const AuthApiService = {
  verifyEmail(email: string) {
    return fetch(`${config.API_ENDPOINT}/users/validEmail?email=${email}`).then(
      (res) => res.json()
    );
  },
  postCompany(company: {
    company_name: string,
    company_address: string,
    company_city: string,
    company_state: string,
    company_zipcode: string,
    company_phonenumber: string,
    company_tax_id: string
  }) {
    return fetch(`${config.API_ENDPOINT}/companies`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(company),
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  postUser(user: {
    full_name: string;
    email: string;
    password: string;
    team_role: string;
    company_name: string;
  }) {
    return fetch(`${config.API_ENDPOINT}/users`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    }).then((res) =>
      !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
    );
  },
  postLogin(credentials: { email: string; password: string }) {
    return fetch(`${config.API_ENDPOINT}/auth/login`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(credentials),
    })
      .then((res) =>
        !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json()
      )
      .then((res) => {
        TokenService.saveAuthToken(res.authToken);
        return res;
      });
  },
  validatePassword(password: string) {
    if (password.length < 8) {
      return "Password must be longer than 8 characters";
    }
    if (password.length > 72) {
      return "Password must be less than 72 characters";
    }
    if (password.startsWith(" ") || password.endsWith(" ")) {
      return "Password must not start or end with empty spaces";
    }
    if (!REGEX_UPPER_LOWER_NUMBER_SPECIAL.test(password)) {
      return "Password must contain 1 upper case, lower case, number and special character";
    }
    return null;
  },
  sendResetCode(email: string) {
    return fetch(`${config.API_ENDPOINT}/users/forgotPassword`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    })
      .then(
        (res) => {
          if (res.status === 200) {
            TokenService.saveUserName(email);
          }
        }
        // !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json();
      )
      .catch((err) => console.log("err", err));
  },
  verifyCode(email: string, code: string) {
    return fetch(
      `${config.API_ENDPOINT}/users/verifyCode?email=${email}&userCode=${code}`
    ).then((res) => res.json());
  },
  resetPassword(email: string, newPassword: string) {
    return fetch(`${config.API_ENDPOINT}/users/resetPassword`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ email: email, newPassword: newPassword }),
    }).then((res) => res.json());
  },
  updatePassword(email: string, oldPassword: string, newPassword: string) {
    return fetch(`${config.API_ENDPOINT}/users/resetPassword`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        newPassword: newPassword,
        oldPassword: oldPassword,
      }),
    }).then((res) => res.json());
  },
};

export default AuthApiService;
