import { config } from "../config";
import { TokenService } from "./token.service";
import { TireModel } from "../Model/tire";

export class TireService {
  getMyOwnTires = async (): Promise<any[]> => {
    const response = await fetch(
      `${
        config.API_ENDPOINT
      }/tires/all?company_id=${TokenService.getCompanyId()}`
    );
    const jsonResponse = await response.json();
    return jsonResponse;
  };
  queryTiresBySize =async (size: string) => {
    const response = await fetch(`${
      config.API_ENDPOINT
    }/tires/search?size=${size}&companyId=${TokenService.getCompanyId()}`)
    const jsonResponse = await response.json()
    return jsonResponse
  }
  getMyOwnTiresBySize = async (size: string, condition: string) => {
    console.log(size, condition, TokenService.getCompanyId())
    const response = await fetch(
      `${
        config.API_ENDPOINT
      }/tires/search?size=${size}&condition=${condition}&companyId=${TokenService.getCompanyId()}`
    );
    const jsonResponse = await response.json();
    console.log(jsonResponse)
    return jsonResponse;
  };
  postNewTire = async (tire: TireModel) => {
    const response = await fetch(`${config.API_ENDPOINT}/tires/addTire`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tire),
    });
    const jsonResponse = await response.json();
    return jsonResponse;
  };

  getTireById = async (id: string) => {
    const response = await fetch(
      `${config.API_ENDPOINT}/tires/details?tireId=${id}`
    );
    const jsonResponse = await response.json();
    return jsonResponse;
  };
}
