import React, { useState } from "react"
import  UserContext  from "./UserContext"
import TokenService from "../Services/token-service"
import { CompanyType } from "../types/CompanyType"

interface IUserProviderProps {
  children: React.ReactNode
}

export const UserProvider: React.FC<IUserProviderProps> = ({ children }) => {

    const [isUserAuthenticated, setIIsUserAuthenticated] = useState(false)
    const [companyName, setICompanyName] = useState<string | null>(null)
    const [user, setIUser] = useState<string | null>(TokenService.getAuthToken())
    const [companyId, setICompanyId] = useState<number | null>(null)
    const [companyInfo, setICompanyInfo] = useState<CompanyType | null>(null)
    const [userEmail, setIUserEmail] = useState<string | null>(null)
    const [isSubActive, setIIsSubActive] = useState(false)
  
    const setIsSubActive = (active: boolean) => {
      setIIsSubActive(active)
    };
  
    const setUserEmail = (email: string) => {
      setIUserEmail(email)
    };
  
    const setCompanyInfo = (info: CompanyType) => {
      setICompanyInfo(info);
    };
  
    const setCompanyId = (id: number) => {
      setICompanyId(id)
    };
  
    const setUser = (Iuser: string) => {
      setIUser(Iuser)
    };
    const setAuth = (authState: boolean) => {
      setIIsUserAuthenticated(authState)
    };
    const setCompanyName = (compName: string) => {
      setICompanyName(compName)
    };
  
    const value = {
      isUserAuthenticated: isUserAuthenticated,
      companyName: companyName,
      setAuth: setAuth,
      setCompanyName: setCompanyName,
      setUser: setUser,
      user: user,
      companyId: companyId,
      setCompanyId: setCompanyId,
      companyInfo: companyInfo,
      setCompanyInfo: setCompanyInfo,
      userEmail: userEmail,
      setUserEmail: setUserEmail,
      isSubActive: isSubActive,
      setIsSubActive: setIsSubActive,
    };
    return (
      <UserContext.Provider value={value}>
        {children}
      </UserContext.Provider>
    );
  
  }
  
  