import React, { FC, ReactNode } from "react"
import styles from "./Form.module.scss"

interface IFormProps {
    children: ReactNode
    onSubmit: () => void
}

export const Form: FC<IFormProps> = ({ children, onSubmit }) => {

    const onFormSubmit = (ev: React.FormEvent<HTMLElement>) => {
        ev.preventDefault()
        onSubmit()
    }

    return (
        <form className={styles.form} onSubmit={(ev) => onFormSubmit(ev)}>
            {children}
        </form>
    )
}