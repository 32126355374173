import {config} from "../config";
import TokenService from "./token-service";

const TiresApiService = {
  getTireParameters() {
    return fetch(`${config.API_ENDPOINT}/tires`).then((res) => res.json());
  },

  getTiresBySize(size: string, condition: string) {
    return fetch(
      `${config.API_ENDPOINT}/tires/search?size=${size}&condition=${condition}`
    ).then((res) => res.json());
  },
  getTiresBySizeInventory(size: string, condition: string, companyId: string) {
    return fetch(
      `${config.API_ENDPOINT}/tires/search?size=${size}&condition=${condition}&companyId=${companyId}`
    ).then((res) => res.json());
  },
  searchTiresBySize(size:string) {
    return fetch(
      `${
        config.API_ENDPOINT
      }/tires/search?size=${size}&companyId=${TokenService.getUserId()}`
    ).then((res) => res.json());
  },
  getTireById(id: string) {
    return fetch(`${config.API_ENDPOINT}/tires/details?tireId=${id}`).then(
      (res) => res.json()
    );
  },
  addNewTire(newTire: {
    brand: string,
    size: string,
    quantity: string,
    condition: string,
    details: string,
    img: string,
    company_id: string,
  }) {
    return fetch(`${config.API_ENDPOINT}/tires/addTire`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newTire),
    });
  },
  deleteTire(tireId: string) {
    return fetch(`${config.API_ENDPOINT}/tires/delete?tireId=${tireId}`, {
      method: "DELETE",
    });
  },
};

export default TiresApiService;
