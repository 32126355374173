import { FC } from "react"
import { Button } from "../../common/Button"
import { Container } from "../../common/Container"
import { Section } from "../../common/Section"
import { Typography } from "../../common/Typography"
import { FormInput } from "../../components/FormInput/FormInput"
import styles from "./index.module.scss"
import { Loading } from "../../common/Loading"

interface ISendCodeProps {
    setEmail: (_: string) => void
    sendResetCode: () => void
    isLoading: boolean
}

export const SendCode: FC<ISendCodeProps> = ({ setEmail, isLoading, sendResetCode }) => {
    return (
        <Section>
            <Container className={styles["reset-box"]} align="left" orientation="vertical">
                <Typography type="h2"  >
                    Reset password
                </Typography><br />
                <Typography type="h4">
                    Enter the email address asscociated with your
                    account and we will send
                    you a reset code.
                </Typography>
            </Container>
            <FormInput
                placeholder="Email"
                name="email"
                orientation="vertical"
                onChange={setEmail} />
            {isLoading ? <Loading /> :
                <Button
                    onClick={sendResetCode}
                    type="primary"
                    size="large">
                    Send Code
                </Button>}
        </Section>
    )
}