import React, { useState, useEffect, FC, useContext } from "react";
import "./DetailPage.css";
import { useParams, useHistory } from "react-router";
import TiresApiService from "../../Services/tires-api-service";
import WheelApiService from "../../Services/wheel-api-service";
import { BiArrowBack } from "react-icons/bi";
import CompanyInfoComponent from "../../components/CompanyInfoComponent/CompanyInfoComponent";
import ReactLoading from "react-loading";
// import TokenService from "../../Services/token-service";

//Updated Imports

import { StoreContext } from "../../store.context";
import { TokenService } from "../../Services/token.service";
import { AppPage } from "../../common/AppPage";

interface DetailPageProps {}

type Iparams = {
  partType: string;
  itemId: string;
  companyId: string;
};

const DetailPage: FC<DetailPageProps> = (props) => {
  const { tireStore } = useContext(StoreContext);
  const history = useHistory();

  const { partType, itemId } = useParams<Iparams>();

  const [location, setLocation] = useState("");
  const [interchange, setInterchange] = useState("");
  const [barcode, setBarcode] = useState("");
  const [title, setTitle] = useState("");
  const [secondLine, setSecondLine] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [companyId, setCompanyId] = useState<string>("");

  const deleteItem = () => {
    setIsLoading(true);
    if (partType === "tires") {
      TiresApiService.deleteTire(itemId);
      setIsLoading(false);
      history.push("/inventory");
    }
    if (partType === "customWheels") {
      WheelApiService.deleteCustomWheel(itemId);
      history.push("/home");
      setIsLoading(false);
    }
    if (partType === "oemWheels") {
      WheelApiService.deleteOemWheel(itemId);
      history.push("/inventory");
      setIsLoading(false);
    }
  };

  const populateView = async () => {
    console.log(TokenService.getCompanyId(), companyId);

    const response = await tireStore.getTireById(itemId);
    setCompanyId(response[0].company_id);
    setTitle(`${response[0].brand} ${response[0].size} `);
    setSecondLine(
      `Condition: ${response[0].condition} Quantity: ${response[0].quantity}`
    );
    setDescription(response[0].details);
    setImageSrc(response[0].img);
  };

  useEffect(() => {
    if (partType === "tires") {
      populateView();
      return;
    }

    if (partType === "customWheels") {
      WheelApiService.getCustomWheelById(itemId).then((res) => {
        setTitle(
          `${res[0].brand} ${res[0].wheel_diameter} X ${res[0].wheel_width}`
        );
        setSecondLine(
          `Bolt Pattern: ${res[0].bolt_pattern} Quantity:${res[0].quantity}`
        );
        setImageSrc(res[0].img);
        setDescription(res[0].wheel_description);
      });
    }
    if (partType === "oemWheels") {
      WheelApiService.getOemWheelById(itemId).then((res) => {
        setCompanyId(res[0].company_id);
        setInterchange(res[0].interchange);
        setLocation(res[0].spot);
        setBarcode(res[0].barcode);
        setTitle(`${res[0].car_year} ${res[0].make} ${res[0].model} `);
        setSecondLine(
          `${res[0].wheel_diameter} X ${res[0].wheel_width} Quantity: ${res[0].quantity}`
        );
        setImageSrc(res[0].img);
      });
    }
  }, [itemId]);

  return (
    <AppPage>
      <div className="detail__page__container">
        {isLoading ? (
          <div className="loading__container">
            <ReactLoading />
          </div>
        ) : (
          <>
            <div className="detail__header__container">
              <BiArrowBack
                onClick={() => history.goBack()}
                color={"#fff"}
                size={30}
                className="detail__page__back__arrow"
              />
            </div>
            <div className="detail__page__header">
              <p className="detail__page__spec">{title}</p>
              <p className="detail__page__spec">{secondLine}</p>
            </div>
            <div className="detail__page__image__container">
              <img
                alt="item"
                width={"100%"}
                className="detail__page__image"
                src={imageSrc}
              />
            </div>
            {partType === "oemWheels" && (
              <div className="detail__page__oem__details">
                <p style={{ color: "#fff" }}> Location: {location}</p>
                <p style={{ color: "#fff" }}> Interchange: {interchange}</p>
                <p style={{ color: "#fff" }}> Barcode: {barcode}</p>
              </div>
            )}

            <CompanyInfoComponent companyId={companyId} />
            {companyId.toString() ===
              TokenService.getCompanyId()?.toString() && (
              <div className="result__block__button__container">
                <button
                  onClick={deleteItem}
                  className="result__block__button delete__btn"
                >
                  Delete
                </button>
                <button
                  onClick={() =>
                    history.push(`/editItem/${partType}/${itemId}`)
                  }
                  className="result__block__button edit__btn"
                >
                  Edit
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </AppPage>
  );
};

export default DetailPage;
