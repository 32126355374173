import React from "react";

type WheelContextType = {
  carMake: string[],
  carModel: string[][],
  carYear: string[],
  wheelList: string[],
  boltPattern: string[],
  oemWheelResults: string[],
  selectedOemWheel: any,
  selectedCustomWheel: any,
  oemWheelSearched: any,
  customWheelSearched: any,
  customWheelResults: any[],
  oemWheelDraft: any,
  oemWheelInventory: any,
  oemInventoryResults: any[],
  customWheelDraft: any,
  selectedInventoryOemWheel: any,
  customWheelInventory: any,
  inventoryCustomWheelResults: any[],
  selectedInventoryCustomWheel: any,
  setCustomWheelSearched: (a: any) => void,
  setOemWheelSearched: (a: any) => void,
  setSelectedCustomWheel: (a: any) => void,
  setSelectedOemWheel: (a: any) => void,
  setCustomWheelResults: (a: any) => void,
  setWheelParameters: (a: string, b:string, c:string, d:string) => void,
  setWheelList: (a: any) => void,
  setOemWheelResults: (a: any) => void,
  setOemWheelDraft: (a: any) => void,
  setCustomWheelDraft: (a: any) => void,
  setOemWheelInventory: (a: any) => void,
  setOemInventoryResults: (a: any) => void,
  setSelectedInventoryOemWheel: (a: any) => void,
  setCustomWheelInventory: (a: any) => void,
  setInventoryCustomWheelResults: (a: any) => void,
  setSelectedInventoryCustomWheel: (a: any) => void,
}

const WheelContext = React.createContext<WheelContextType | null>({
  carMake: [],
  carModel: [],
  carYear: [],
  wheelList: [],
  boltPattern: [],
  oemWheelResults: [],
  selectedOemWheel: {},
  selectedCustomWheel: {},
  oemWheelSearched: {},
  customWheelSearched: {},
  customWheelResults: [],
  oemWheelDraft: {},
  customWheelDraft: {},
  oemWheelInventory: {},
  oemInventoryResults: [],
  selectedInventoryOemWheel: {},
  customWheelInventory: {},
  inventoryCustomWheelResults: [],
  selectedInventoryCustomWheel: {},
  setCustomWheelSearched: () => { },
  setOemWheelSearched: () => { },
  setSelectedCustomWheel: () => { },
  setSelectedOemWheel: () => { },
  setCustomWheelResults: () => { },
  setWheelParameters: () => { },
  setWheelList: () => { },
  setOemWheelResults: () => { },
  setOemWheelDraft: () => { },
  setCustomWheelDraft: () => { },
  setOemWheelInventory: () => { },
  setOemInventoryResults: () => { },
  setSelectedInventoryOemWheel: () => { },
  setCustomWheelInventory: () => { },
  setInventoryCustomWheelResults: () => { },
  setSelectedInventoryCustomWheel: () => { },
});

export default WheelContext;

export class WheelProvider extends React.Component<any, any> {
  state = {
    carMake: [],
    carModel: [],
    carYear: [],
    boltPattern: [],
    wheelList: [],
    oemWheelResults: [],
    customWheelResults: [],
    oemWheelDraft: {},
    customWheelDraft: {},
    selectedOemWheel: {},
    selectedCustomWheel: {},
    oemWheelSearched: {},
    customWheelSearched: {},
    oemWheelInventory: {},
    oemInventoryResults: [],
    selectedInventoryOemWheel: {},
    customWheelInventory: {},
    inventoryCustomWheelResults: [],
    selectedInventoryCustomWheel: {},
  };

  setSelectedInventoryCustomWheel = (wheel: any) => {
    this.setState({
      selectedInventoryCustomWheel: wheel,
    });
  };

  setInventoryCustomWheelResults = (wheels: any) => {
    this.setState({
      inventoryCustomWheelResults: wheels,
    });
  };

  setCustomWheelInventory = (wheel: any) => {
    this.setState({
      customWheelInventory: wheel,
    });
  };

  setSelectedInventoryOemWheel = (wheel: any) => {
    this.setState({
      selectedInventoryOemWheel: wheel,
    });
  };

  setOemInventoryResults = (wheels : any) => {
    this.setState({
      oemInventoryResults: wheels,
    });
  };

  setOemWheelInventory = (wheel: any) => {
    this.setState({
      oemWheelInventory: wheel,
    });
  };

  setCustomWheelSearched = (wheel: any) => {
    this.setState({
      customWheelSearched: wheel,
    });
  };

  setOemWheelSearched = (wheel : any) => {
    this.setState({
      oemWheelSearched: wheel,
    });
  };

  setSelectedCustomWheel = (wheel: any) => {
    this.setState({
      selectedCustomWheel: wheel,
    });
  };

  setSelectedOemWheel = (wheelId: any) => {
    this.setState({
      selectedOemWheel: wheelId,
    });
  };

  setCustomWheelDraft = (draft: any) => {
    this.setState({
      customWheelDraft: draft,
    });
  };

  setOemWheelDraft = (draft: any) => {
    this.setState({
      oemWheelDraft: draft,
    });
  };

  setWheelParameters = (make: string, model: string, year: string, wBoltPattern: string) => {
    this.setState({
      carMake: make,
      carModel: model,
      carYear: year,
      boltPattern: wBoltPattern,
    });
  };

  setWheelList = (wheels: any) => {
    this.setState({
      wheelList: wheels,
    });
  };

  setOemWheelResults = (wheels: any) => {
    this.setState({
      oemWheelResults: wheels,
    });
  };

  setCustomWheelResults = (wheels: any) => {
    this.setState({
      customWheelResults: wheels,
    });
  };

  render() {
    const value = {
      carMake: this.state.carMake,
      carModel: this.state.carModel,
      carYear: this.state.carYear,
      boltPattern: this.state.boltPattern,
      wheelList: this.state.wheelList,
      oemWheelResults: this.state.oemWheelResults,
      customWheelResults: this.state.customWheelResults,
      oemWheelDraft: this.state.oemWheelDraft,
      customWheelDraft: this.state.customWheelDraft,
      customWheelSearched: this.state.customWheelSearched,
      setCustomWheelSearched: this.setCustomWheelSearched,
      setCustomWheelDraft: this.setCustomWheelDraft,
      setOemWheelDraft: this.setOemWheelDraft,
      setWheelParameters: this.setWheelParameters,
      setWheelList: this.setWheelList,
      setOemWheelResults: this.setOemWheelResults,
      selectedOemWheel: this.state.selectedOemWheel,
      setSelectedOemWheel: this.setSelectedOemWheel,
      selectedCustomWheel: this.state.selectedCustomWheel,
      setSelectedCustomWheel: this.setSelectedCustomWheel,
      setCustomWheelResults: this.setCustomWheelResults,
      oemWheelSearched: this.state.oemWheelSearched,
      setOemWheelSearched: this.setOemWheelSearched,
      oemWheelInventory: this.state.oemWheelInventory,
      setOemWheelInventory: this.setOemWheelInventory,
      oemInventoryResults: this.state.oemInventoryResults,
      setOemInventoryResults: this.setOemInventoryResults,
      selectedInventoryOemWheel: this.state.selectedInventoryOemWheel,
      setSelectedInventoryOemWheel: this.setSelectedInventoryOemWheel,
      customWheelInventory: this.state.customWheelInventory,
      setCustomWheelInventory: this.setCustomWheelInventory,
      inventoryCustomWheelResults: this.state.inventoryCustomWheelResults,
      setInventoryCustomWheelResults: this.setInventoryCustomWheelResults,
      setSelectedInventoryCustomWheel: this.setSelectedInventoryCustomWheel,
      selectedInventoryCustomWheel: this.state.selectedInventoryCustomWheel,
    };

    return (
      <WheelContext.Provider value={value}>
        {this.props.children as React.ReactNode}
      </WheelContext.Provider>
    );
  }
}
