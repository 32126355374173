import "./ChatPage.css";
import { AppPage } from "../../common/AppPage";

const ChatPage = () => {
  return (
    <AppPage className="chat__page">
      <iframe
        title="360 Chat"
        className="chat"
        src="https://alenj-dmt.github.io/cautious-doodle/#/rooms/50OkbmJUkuWtIH2KJTiA"
      ></iframe>
    </AppPage>
  );
};

export default ChatPage;
